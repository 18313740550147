var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import Component from 'vue-class-component';
import Vue from 'vue';
import { __ } from '../../../global/language';
const Props = Vue.extend({
    props: {
        isQuestion: {
            type: Boolean,
            default: false,
        },
        isInteraction: {
            type: Boolean,
            default: false,
        },
        isMenu: {
            type: Boolean,
            default: false,
        },
        isNew: {
            type: Boolean,
            default: false,
        },
        isBeta: {
            type: Boolean,
            default: false,
        },
    }
});
let InteractionLabel = class InteractionLabel extends Props {
    constructor() {
        super(...arguments);
        this.label = null;
    }
    setLabelTranslation() {
        return __awaiter(this, void 0, void 0, function* () {
            this.label = yield __(this.isBeta ? 'ribbon_label_BetaFeature' : 'new');
        });
    }
    created() {
        this.setLabelTranslation();
    }
};
InteractionLabel = __decorate([
    Component
], InteractionLabel);
export default InteractionLabel;
