<template>
    <header class="w-full relative">
        <div class="background absolute w-full z-0">
            <div class="absolute inset-0 z-5"></div>

            <img :src="activePlaylist.header_img_url"
                 class="image w-full object-cover h-64"
                 alt=""/>
        </div>

        <div class="container relative w-full mx-auto px-4 pt-4 z-10 justify-center">
            <div class="flex w-1/2 mx-auto mb-4 h-16 md:h-32 space-x-4 overflow-hidden">
                <div class="logo flex-none">
                    <img :src="activePlaylist.header_logo_url"
                         class="h-16 w-auto flex"
                         :alt="translations['playlist-logo-alt'] ?? ''"
                         :title="translations['playlist-logo-title'] ?? ''"/>
                </div>

                <div class="flex-auto">
                    <h3 class="title flex-auto text-brand-600 mt-0"
                        v-text="activePlaylist.title"></h3>

                    <p class="description flex-none text-brand-default leading-normal invisible md:visible"
                       v-html="activePlaylist.description"></p>
                </div>
            </div>

            <playlist-search @search="search"/>

            <playlist-nav :playlist="activePlaylist"
                          :active-playlist-id="$props.activePlaylistId"/>
        </div>
    </header>
</template>

<script>
    import axios from '../../../global/axios';
    import {route} from 'ziggy-js';

    import PlaylistSearch from './PlaylistSearch';
    import PlaylistNav from './PlaylistNav';
    import { __ } from '../../../global/language';

    export default {
        name: 'PageHeader',
        components: {
            PlaylistSearch,
            PlaylistNav,
        },
        data() {
            return {
                activePlaylist: {},
                translations: {},
            }
        },
        mounted() {
            this.getPlaylistInfo(route('playlist.info', {playlist_id: this.activePlaylistId}));

            __([
                'playlist-logo-alt',
                'playlist-logo-title',
            ])
                .then(translations => {
                    this.translations = translations;
                });
        },
        methods: {
            getPlaylistInfo(url) {
                axios.get(url)
                    .then(({data}) => {
                        this.activePlaylist = data;
                    })
                    .catch(error => {
                        console.log(error);
                    })
            },
            search(keywords) {
                this.$emit('search', keywords);
            },
        },
        props: {
            activePlaylistId: Number,
        },
    }
</script>
