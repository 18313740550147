var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Component from 'vue-class-component';
import UploadVideoItem from './UploadVideoItem.vue';
import axios from '../../../global/axios';
import { Debounce } from 'vue-debounce-decorator';
import pagination from 'laravel-vue-pagination/src/LaravelVuePagination.vue';
import { route } from 'ziggy-js';
import { __ } from '../../../global/language';
import GlobalMixin from '../../mixins/global';
let UploadVideoList = class UploadVideoList extends GlobalMixin {
    constructor() {
        super(...arguments);
        this.videos = [];
        this.paginate = {};
        this.loading = true;
        this.initialListLoaded = false;
        this.selectedVideoId = null;
        this.translations = {};
    }
    created() {
        // eslint-disable-next-line no-undef
        window.addEventListener('select-panel-activated', ((event) => this.panelActivated(event)));
    }
    mounted() {
        __([
            'search',
            'loading',
            'noUploadedVideos',
            'enrich',
        ])
            .then(translations => {
            this.translations = translations;
        });
    }
    destroyed() {
        // eslint-disable-next-line no-undef
        window.removeEventListener('select-panel-activated', ((event) => this.panelActivated(event)));
    }
    selectVideo(videoId) {
        this.selectedVideoId = videoId;
    }
    selectAndCreate(videoId) {
        this.selectVideo(videoId);
        this.createVideo();
    }
    panelActivated(evt) {
        if (evt.detail !== 'upload') {
            return;
        }
        if (this.initialListLoaded) {
            return;
        }
        this.initialListLoaded = true;
        this.fetchVideos();
    }
    fetchVideos(page = 1, search = '') {
        this.loading = true;
        this.selectedVideoId = null;
        const url = route('ajax.upload.videos', { page, search });
        axios.get(url)
            .then(({ data: response }) => {
            this.paginate = response;
            this.videos = response.data;
        })
            .catch((error) => console.log(error))
            .finally(() => this.loading = false);
    }
    createVideo() {
        if (!this.selectedVideoId) {
            return;
        }
        const video = this.videos.find((video) => {
            return video.id === this.selectedVideoId;
        });
        if (!video) {
            return;
        }
        axios.put(route('ajax.video.new'), {
            container_id: video.container.id,
            duration: video.duration,
            hihaho_video: true,
            private: true,
            title: video.name,
            type: video.type,
            property: video.property,
            verifier: video.verifier,
            videoUrl: video.video_key,
            video_id: video.id,
        })
            .then(({ data }) => {
            location.href = data.redirect_url;
        })
            .catch((error) => window.showXhrErrorAlert(error));
    }
};
__decorate([
    Debounce(500)
], UploadVideoList.prototype, "fetchVideos", null);
UploadVideoList = __decorate([
    Component({
        components: {
            UploadVideoItem,
            pagination,
        },
    })
], UploadVideoList);
export default UploadVideoList;
