var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import VideoMixin from '../../mixins/video';
import Component from 'vue-class-component';
import Vue from 'vue';
const Props = Vue.extend({
    props: {
        video: {
            type: Object,
            required: true,
        },
    }
});
let UploadVideoItem = class UploadVideoItem extends Props {
};
UploadVideoItem = __decorate([
    Component({
        mixins: [VideoMixin]
    })
], UploadVideoItem);
export default UploadVideoItem;
