var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import BootstrapModal from './../BootstrapModal.vue';
import VideoOrderForm from './../../billing/VideoOrderForm.vue';
import Component from 'vue-class-component';
import { vxm } from '../../../store/store';
import GlobalMixin from '../../../mixins/global';
import PaymentIcon from '../../svg/dashboard/PaymentIcon.vue';
import { __ } from '../../../../global/language';
let AfterPaymentResponse = class AfterPaymentResponse extends GlobalMixin {
    constructor() {
        super(...arguments);
        this.modalName = 'billing.video-order-feedback';
        this.translations = {};
    }
    mounted() {
        __([
            'paymentPendingMessageTitle',
            'paymentSuccessMessageTitle',
            'paymentFailedMessageTitle',
            'exploreHihaho',
            'retryPayment',
            'paymentPendingMessageHeader',
            'paymentSuccessMessageHeader',
            'paymentFailedMessageHeader',
            'paymentPendingMessageBody',
            'paymentSuccessMessageBody',
            'paymentFailedMessageBody',
            'purchaseVideosModalPaymentDetails',
            'paymentResponseChooseVideos',
            'paymentFailedMessageEnd',
            'paymentSuccessMessageEnd',
        ])
            .then(translations => {
            this.translations = translations;
        });
    }
    setModal(modal) {
        vxm.billing.activeModal = modal;
    }
    get videoOrderDetails() {
        var _a;
        return (_a = vxm.initialState.values.billing) === null || _a === void 0 ? void 0 : _a.video_order_details;
    }
    get isPaymentPending() {
        if (typeof this.videoOrderDetails === 'undefined') {
            // It's not pending, it's undefined, so we return false
            return false;
        }
        return (this.videoOrderDetails.mollie_payment_status === 'pending' || this.videoOrderDetails.mollie_payment_status === 'open');
    }
    get isPaymentSuccessful() {
        if (this.isPaymentPending) {
            return false;
        }
        if (typeof this.videoOrderDetails === 'undefined') {
            return false;
        }
        return this.videoOrderDetails.mollie_payment_status === 'paid';
    }
    get paymentTitle() {
        if (this.isPaymentPending) {
            return this.translations.paymentpendingmessagetitle;
        }
        return this.isPaymentSuccessful
            ? this.translations.paymentsuccessmessagetitle
            : this.translations.paymentfailedmessagetitle;
    }
    get buttonText() {
        return this.isPaymentSuccessful
            ? this.translations.explorehihaho
            : this.translations.retrypayment;
    }
    get paymentMessagesHeader() {
        if (this.isPaymentPending) {
            return this.translations.paymentpendingmessageheader;
        }
        return this.isPaymentSuccessful
            ? this.translations.paymentsuccessmessageheader
            : this.translations.paymentfailedmessageheader;
    }
    get paymentMessageBody() {
        if (this.isPaymentPending) {
            return this.translations.paymentpendingmessagebody;
        }
        return this.isPaymentSuccessful
            ? this.translations.paymentsuccessmessagebody
            : this.translations.paymentfailedmessagebody;
    }
};
AfterPaymentResponse = __decorate([
    Component({
        components: {
            VideoOrderForm,
            BootstrapModal,
            PaymentIcon,
        },
    })
], AfterPaymentResponse);
export default AfterPaymentResponse;
