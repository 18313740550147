var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.hasFiles
    ? _c("div", [
        _c(
          "button",
          {
            staticClass:
              "files-folded text-center border-brand-600 text-brand-600 activated:rounded-xl rounded-xl border-2 p-1 pl-3 font-bold text-xs focus:outline-none",
            on: {
              click: function ($event) {
                return _vm.toggleExpanded()
              },
            },
          },
          [
            _c("span", {
              domProps: {
                textContent: _vm._s(
                  _vm.translations["show-files-pretext"] ?? ""
                ),
              },
            }),
            _vm._v(" "),
            _c("span", {
              domProps: { textContent: _vm._s(this.files.length) },
            }),
            _vm._v(" "),
            _c("span", {
              domProps: {
                textContent: _vm._s(
                  _vm.translations["show-files-posttext"] ?? ""
                ),
              },
            }),
            _vm._v(" "),
            _c(
              "i",
              {
                staticClass:
                  "material-icons font-bold text-xs float-right mr-1 mt-1 ml-4",
              },
              [_vm._v("\n            folder\n        ")]
            ),
          ]
        ),
        _vm._v(" "),
        _vm.isExpanded
          ? _c("div", { staticClass: "file-list mt-2" }, [
              _c(
                "div",
                { staticClass: "grid grid-cols-6 md:grid-cols-12 gap-2" },
                _vm._l(_vm.files, function (file) {
                  return _c("file", { key: file.id, attrs: { file: file } })
                }),
                1
              ),
            ])
          : _vm._e(),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }