var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "playlists pt-4 pb-6" }, [
    _c(
      "div",
      { staticClass: "playlist-row grid grid-cols-3 md:grid-cols-5 gap-3" },
      [
        _c(
          "nav-item",
          _vm._b(
            { attrs: { "active-playlist-id": _vm.activePlaylistId } },
            "nav-item",
            { playlist: _vm.playlist.parent || _vm.playlist },
            false
          )
        ),
        _vm._v(" "),
        _vm._l(_vm.playlist.children, function (childPlaylist) {
          return _c(
            "nav-item",
            _vm._b(
              {
                key: childPlaylist.id,
                attrs: { "active-playlist-id": _vm.activePlaylistId },
              },
              "nav-item",
              { playlist: childPlaylist },
              false
            )
          )
        }),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }