<template>
    <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 144 206">
        <path class="cls-1"
              d="M98.43,0H-.48V206h145V54.43Zm4.09,28.46L120.4,49.59H102.52ZM14.78,190.74V15.26H87.26V64.85h42V190.74Z"/>
        <polygon class="cls-1"
                 points="78.72 131.41 64.53 106 33.01 164 61.01 164 96.92 164 111.69 164 86.01 118 78.72 131.41"/>
        <path class="cls-1" d="M84.93,108A10,10,0,1,0,74.88,98,10,10,0,0,0,84.93,108Z"/>
    </svg>
</template>

<script>
    export default {
        name: 'ImageIcon'
    }
</script>

<style scoped>
    .cls-1 {
        fill: #333;
    }
</style>
