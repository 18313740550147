<template v-if="file">
    <div class="file items-center justify-center">
        <a :href="file.url"
           target="_blank"
           :title="file.extension_type"
           class="w-full items-center">
            <div class="description text-brand-500 text-center
                        items-center justify-center content-center w-full text-xs">
                <file-extension-icon class="h-10 inline"
                                     :name="file.extension_type"/>

                <p class="w-full truncate"
                   v-text="file.title"></p>
            </div>
        </a>
    </div>
</template>

<script>
    import FileExtensionIcon from '../svg/playlist/FileExtensionIcon.vue'

    export default {
        name: 'File',
        components: {
            FileExtensionIcon,
        },
        props: [
            'file',
        ],
    }
</script>
