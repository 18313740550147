<template>
    <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 144 206">
        <path class="cls-1"
              d="M98.43,0H-.48V206h145V54.43Zm4.09,28.46L120.4,49.59H102.52ZM14.78,190.74V15.26H87.26V64.85h42V190.74Z"/>
        <text class="cls-2" transform="translate(23.78 133.29)">DOC</text>
    </svg>
</template>

<script>
    export default {
        name: 'DocIcon',
    }
</script>

<style scoped>
    .cls-1, .cls-2 {
        fill: #333;
    }

    .cls-2 {
        font-size: 44.14px;
        font-family: OpenSans-Extrabold, Open Sans, serif;
        font-weight: 800;
    }
</style>
