<template>
    <div class="search flex flex-wrap items-stretch w-full relative rounded-xl bg-white">
        <input type="search"
               class="shrink grow
                      appearance-none leading-normal w-px flex-1 h-8 pl-3
                      bg-transparent font-italic relative text-black outline-none"
               :placeholder="translations.search"
               v-model="keywords"
               @keyup.enter="submit"/>

        <div class="flex -mr-px">
            <button class="flex items-center leading-normal
                           whitespace-nowrap text-white text-2xs
                           bg-brand-default rounded-2xl p-2 border-white border-4"
                    @click="submit">
                <i class="material-icons font-bold text-xs">search</i>
            </button>
        </div>
    </div>
</template>

<script>
    import { __ } from '../../../global/language';

    export default {
        name: 'PlaylistSearch',
        data() {
            return {
                keywords: '',
                translations: {},
            }
        },
        mounted() {
            __([
                'search',
            ])
                .then(translations => {
                    this.translations = translations;
                });
        },
        methods: {
            submit() {
                this.$emit('search', this.keywords);
            }
        },
    };
</script>
