<template>
    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
         viewBox="0 0 200 200" style="enable-background:new 0 0 200 200;" xml:space="preserve">
        <g id="grid" class="st0">
	<rect x="0.2" y="0.4" class="st1" width="25" height="199.6"/>
            <rect x="175" y="0.4" class="st1" width="25" height="199.6"/>

            <rect x="87.5" y="-86.9" transform="matrix(-1.836970e-16 1 -1 -1.836970e-16 112.9 -87.1)" class="st1"
                  width="25" height="199.6"/>
            <rect x="87.5" y="88.1" transform="matrix(-1.836970e-16 1 -1 -1.836970e-16 287.9 87.9)" class="st1"
                  width="25" height="199.6"/>
</g>
        <g id="Layer_1" class="st0">
	<g class="st2">
		<path class="st3" d="M157.2,78.6c-1.1-0.6-7.4-3.8-16.3-8.5l1.3-1.6c2.7-3.5,4-7.9,3.4-12.3c-0.5-4.4-2.8-8.4-6.3-11.1
			c-3-2.3-6.5-3.5-10.3-3.5h0c-5.2,0-9.9,2.3-13.1,6.4l-5.2,6.6C85.9,41.7,60.2,28.3,59.2,27.8c0.1,0.1-1.4-0.8-3.1-1.3
			c-10.8-3.9-23.5,1.4-28.4,11.8c-3.4,7-2.2,12.1-2.4,12.5c0,10.7,0,59.4,0,85.3c0.3,14.4-1.2,19.5,2.7,27.2h0
			c5.3,10.3,17.8,14.9,28.4,11.3c5.8-2.3,19.4-10.1,45.4-23.7c26.9-14.2,56.8-30,56.8-30c0.4-0.2,1-0.5,1.5-0.9
			c6.9-4.3,11.2-12.1,11.1-20.3C171.1,87.5,161.8,80.5,157.2,78.6z M119,50.4c2.5-3.2,6.3-4.9,10.1-4.9c2.8,0,5.6,0.9,7.9,2.7
			c5.6,4.4,6.5,12.4,2.2,18l-1.7,2.2c-6.9-3.6-14.9-7.7-23.2-12L119,50.4z"/>
        <path class="st4" d="M78.4,135.7c-3.8,0-7.3-1.6-9.8-4.5L39,96.3c-4.6-5.4-3.9-13.5,1.5-18.1c5.4-4.6,13.5-3.9,18.1,1.5l19.5,23
			l41-52.2c4.4-5.6,12.4-6.5,18-2.2c5.6,4.4,6.5,12.4,2.2,18l-50.6,64.6c-2.4,3-6,4.8-9.8,4.9C78.6,135.7,78.5,135.7,78.4,135.7"/>
	</g>
</g>
        <g id="Layer_4">
	<path class="st5" d="M171.2,99.6c-0.1-12.2-9.4-19.1-14-21c-4.6-2.4-96.1-49.8-98-50.8c0.1,0.1-1.4-0.8-3.1-1.3
		c-10.8-3.9-23.5,1.4-28.4,11.8c-3.4,7-2.2,12.1-2.4,12.5v85.3c0.3,14.4-1.2,19.5,2.7,27.2h0c5.3,10.3,17.8,14.9,28.4,11.3
		c5.8-2.3,19.4-10.1,45.4-23.7c26.9-14.2,56.8-30,56.8-30c0.4-0.2,1-0.5,1.5-0.9C167.1,115.6,171.4,107.8,171.2,99.6"/>
            <path class="st4" d="M115.7,143.8c-2.9,0-5.6-1.3-7.3-3.4l-17.2-21.9L74,140.4c-1.7,2.1-4.4,3.4-7.2,3.4h-9.1c-3.6,0-6.8-1.9-8.2-5
		c-1.3-2.8-0.9-5.9,1.1-8.4L75.6,100L51.3,70.5c-2-2.4-2.4-5.6-1.1-8.4c1.5-3.1,4.6-5,8.2-5h8.9c2.9,0,5.6,1.3,7.3,3.4l16.5,21.1
		L108,60.5c1.7-2.1,4.4-3.4,7.2-3.4h9c3.6,0,6.8,1.9,8.2,5c1.3,2.8,0.9,6-1.1,8.4L106.8,100l25.2,30.4c2,2.4,2.4,5.6,1.1,8.4
		c-1.5,3.1-4.6,5-8.2,5H115.7z"/>
            <path class="st5" d="M134,130.2L109,100l24.4-29.2c2.4-2.9,3-6.7,1.4-10.1c-1.7-3.7-5.4-6-9.7-6h-9.2c-3.3,0-6.5,1.5-8.5,4L91.2,79
		L75.4,58.8c-2-2.5-5.2-4.1-8.6-4.1h-9.1c-4.3,0-8,2.3-9.7,5.9c-1.6,3.4-1.1,7.1,1.3,10.1L73.4,100l-24.9,30.2
		c-2.4,2.9-2.9,6.7-1.3,10.1c1.7,3.7,5.4,5.9,9.7,5.9h9.3c3.4,0,6.5-1.5,8.5-4l16.5-20.9l16.4,20.9c2,2.5,5.2,4,8.6,4h9.3
		c4.3,0,8-2.3,9.7-5.9C136.9,136.9,136.4,133.1,134,130.2z M125.6,143.3h-9.3c-2.5,0-4.8-1.1-6.3-3l-18.7-23.8l-18.8,23.8
		c-1.5,1.8-3.8,2.9-6.3,2.9h-9.3c-6.4,0-10-6.7-6.2-11.3l26.4-32l-25.6-31c-3.8-4.7-0.2-11.3,6.2-11.3h9.1c2.5,0,4.9,1.1,6.3,3
		l18,23l18.4-23.1c1.5-1.8,3.8-2.9,6.3-2.9h9.2c6.4,0,10.1,6.7,6.2,11.4l-25.9,31l26.5,32C135.6,136.6,132,143.3,125.6,143.3z"/>
</g>
        <g id="Layer_2">
</g>
</svg>

</template>

<script>
    export default {
        name: 'PaymentFailureIcon'
    }
</script>

<style scoped>
    .st0 {
        display: none;
    }

    .st1 {
        display: inline;
        fill: #C0CCD1;
    }

    .st2 {
        display: inline;
    }

    .st3 {
        fill: #29A96E;
    }

    .st4 {
        fill: #FFFFFF;
    }

    .st5 {
        fill: #C53030;
    }

</style>
