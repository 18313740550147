var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("page-header", {
        attrs: { "active-playlist-id": _vm.$props.activePlaylistId },
        on: { search: _vm.search },
      }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "container mx-auto px-4" },
        [
          _c("file-list", {
            attrs: { "playlist-id": _vm.$props.activePlaylistId },
          }),
          _vm._v(" "),
          _c("video-list", {
            attrs: {
              "playlist-id": _vm.$props.activePlaylistId,
              keywords: _vm.keywords,
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }