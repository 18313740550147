var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import axios from '../../../../global/axios';
import BootstrapModal from './../BootstrapModal.vue';
import VideoOrderForm from './../../billing/VideoOrderForm.vue';
import Component from 'vue-class-component';
import { vxm } from '../../../store/store';
import VideoPricing from '../../../utils/VideoPricing';
import { route } from 'ziggy-js';
import GlobalMixin from '../../../mixins/global';
import { __ } from '../../../../global/language';
let PurchaseVideosModal = class PurchaseVideosModal extends GlobalMixin {
    constructor() {
        var _a, _b, _c;
        super(...arguments);
        this.modalName = 'billing.purchase-videos';
        this.amountViewsSelected = ((_a = vxm.initialState.values.billing) === null || _a === void 0 ? void 0 : _a.default_amount_video_views) || -1;
        this.amountVideosSelected = ((_b = vxm.initialState.values.billing) === null || _b === void 0 ? void 0 : _b.default_amount_videos) || 1;
        this.enterpriseMailSent = false;
        this.selectedCurrency = ((_c = vxm.initialState.values.billing) === null || _c === void 0 ? void 0 : _c.default_currency) || {
            code: 'EUR',
            symbol: '€',
        };
        this.videoPricing = new VideoPricing();
        this.translations = {};
    }
    mounted() {
        __([
            'ThankYouForContactingUsEnterprise',
            'PurchaseVideosModalContactEnterprise',
            'PurchaseVideosModalTrialEnded',
            'PurchaseVideosModalPaymentDetails',
            'PurchaseVideosModalPriceTaxInclusive',
            'PurchaseVideosModalPriceTaxExclusive',
            'PurchaseVideosModalViews',
            'PurchaseVideosModalVideosX',
            'PurchaseVideosModalSelectVideos',
            'Purchase_videos_modal.exchange_rates.temporarily_not_available',
            'PurchaseVideosModalChoose',
        ])
            .then(translations => {
            this.translations = translations;
        });
    }
    get trialEnded() {
        return vxm.billing.trialEnded;
    }
    sendEnterpriseMail() {
        var _a;
        if (this.enterpriseMailSent) {
            return;
        }
        if ((_a = vxm.initialState.values.user) === null || _a === void 0 ? void 0 : _a.signed_in) {
            axios.post(route('video-order.send-enterprise-mail'))
                .then(() => {
                this.enterpriseMailSent = true;
            })
                .catch((error) => window.showXhrErrorAlert(error));
        }
    }
    get totalPrice() {
        return this.videoPricing.price(this.amountVideosSelected, this.amountViewsSelected, this.selectedCurrency);
    }
    get totalPriceWithDiscount() {
        return this.videoPricing.discountedPrice(this.amountVideosSelected, this.amountViewsSelected, this.selectedCurrency);
    }
    get footerMessages() {
        return this.enterpriseMailSent
            ? this.translations.thankyouforcontactingusenterprise
            : this.translations.purchasevideosmodalcontactenterprise;
    }
    get toggleMailSentClass() {
        return this.enterpriseMailSent ? 'messages-send-success' : 'contact-for-enterprise';
    }
};
PurchaseVideosModal = __decorate([
    Component({
        components: {
            VideoOrderForm,
            BootstrapModal,
        }
    })
], PurchaseVideosModal);
export default PurchaseVideosModal;
