var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import Component from 'vue-class-component';
import Vue from 'vue';
import Dropzone from 'dropzone';
import axios from '../../../global/axios';
import * as tus from 'tus-js-client';
import { route } from 'ziggy-js';
import { __ } from '../../../global/language';
import UploadBgPlatformIcon from '../svg/platforms/UploadBgPlatformIcon.vue';
import { isObject } from '../../../global/helpers/base';
Dropzone.autoDiscover = false;
const Props = Vue.extend({
    props: {
        initialSelectedContainer: {
            type: Object,
        },
    }
});
let VideoUploader = class VideoUploader extends Props {
    constructor() {
        super(...arguments);
        this.amountQueuedFiles = 0;
        this.selectedContainer = this.initialSelectedContainer;
        this.translations = {};
    }
    mounted() {
        __([
            'select_video.replace_video.cannot_select_multiple_videos',
            'VideoUploaderMaxFilesExceeded',
            'VideoUploaderFileTooBig',
            'VideoUploaderInvalidFileType',
            'VideoUploaderResponseError',
            'VideoUploaderSelectVideos',
            'VideoUploaderOrDragVideos',
            'help_steps.select_page.upload.search_tip_choose_video',
            'help_steps.select_page.upload.search_tip_wait_for_upload',
        ])
            .then(translations => {
            var _a, _b;
            this.translations = translations;
            const dictMaxFilesExceeded = ((_a = window.__INITIAL_STATE__.upload) === null || _a === void 0 ? void 0 : _a.replace_video)
                ? translations['select_video.replace_video.cannot_select_multiple_videos']
                : translations.videouploadermaxfilesexceeded;
            this.dropzone = new Dropzone(this.$el, {
                url: '/',
                method: 'put',
                acceptedFiles: 'video/*',
                dictDefaultMessage: this.$refs.defaultMessageTemplate.innerHTML,
                dictRemoveFile: '<div class="icon"><span class="glyphicons remove_sign"></span></div>',
                dictFileTooBig: translations.videouploaderfiletoobig,
                dictInvalidFileType: translations.videouploaderinvalidfiletype,
                dictResponseError: translations.videouploaderresponseerror,
                dictMaxFilesExceeded,
                previewTemplate: this.$refs.previewTemplate.innerHTML,
                createImageThumbnails: false,
                maxFilesize: this.getMaxFileSizeInMb(),
                maxFiles: ((_b = window.__INITIAL_STATE__.upload) === null || _b === void 0 ? void 0 : _b.replace_video) ? 1 : 10,
                autoProcessQueue: false,
                addRemoveLinks: true,
                parallelUploads: 1,
                uploadMultiple: false,
            });
            this.dropzoneListeners();
        });
    }
    setContainer(container) {
        this.selectedContainer = container;
        this.dropzone.options.maxFilesize = this.getMaxFileSizeInMb();
        this.resetFileValidation();
    }
    /**
     * We can reset file validation by removing all files and re-adding them.
     * This helps when a file is too big for one container, but not for another container
     */
    resetFileValidation() {
        const files = this.dropzone.files;
        this.dropzone.removeAllFiles();
        files.forEach((file) => this.dropzone.addFile(file));
    }
    processQueue() {
        return new Promise((resolve, reject) => {
            if (!this.dropzone.files.length) {
                return resolve();
            }
            if (!this.isValid()) {
                __('VideoUploaderInvalidFileSelection').then(translation => reject(translation));
            }
            const queuedFiles = this.dropzone.getQueuedFiles();
            this.$on('queueCompleted', () => resolve());
            this.$on('dropzone-error', (file, message, xhr) => {
                console.log('upload error for file:', file);
                console.log('xhr of upload error:', xhr);
                reject(message);
            });
            (() => __awaiter(this, void 0, void 0, function* () {
                try {
                    const asyncFunctions = queuedFiles.map((file) => () => this.sendFile(file));
                    yield asyncFunctions.reduce((previousPromise, nextAsyncFunction) => __awaiter(this, void 0, void 0, function* () {
                        yield previousPromise;
                        yield nextAsyncFunction();
                    }), Promise.resolve());
                }
                catch (exception) {
                    throw new Error(exception);
                }
            }))()
                .then(() => this.uploadingFinished())
                .catch((reason) => {
                const debugData = {
                    error: reason,
                    allFiles: this.dropzone.files,
                    uploadingFiles: this.dropzone.getUploadingFiles(),
                    queuedFiles: this.dropzone.getQueuedFiles(),
                    completedFiles: this.dropzone.getFilesWithStatus(Dropzone.SUCCESS),
                };
                // Throwing this so this is caught by the external log service
                throw new Error(`Error while uploading: ${JSON.stringify(debugData)}`);
            });
        });
    }
    uploadingFinished() {
        var _a;
        if ((_a = window.__INITIAL_STATE__.upload) === null || _a === void 0 ? void 0 : _a.replace_video) {
            location.href = route('dashboard.settings', {
                video: window.__INITIAL_STATE__.upload.replace_video.uuid,
            }) + '#general';
        }
        else {
            location.href = route('dashboard.home');
        }
    }
    getMaxFileSizeInMb() {
        if (!this.selectedContainer) {
            return 0;
        }
        return Math.max(Math.round(this.selectedContainer.upload_max_file_size / 1024 / 1024), 1);
    }
    dropzoneListeners() {
        this.dropzone.on('error', (file, message, xhr) => this.$emit('dropzone-error', file, message, xhr));
        this.dropzone.on('addedfile', () => {
            this.$nextTick(() => __awaiter(this, void 0, void 0, function* () { return this.amountQueuedFilesChanged(); }));
            window.dispatchEvent(new CustomEvent('upload-container-added-file'));
        });
        this.dropzone.on('removedfile', () => {
            this.$nextTick(() => __awaiter(this, void 0, void 0, function* () { return this.amountQueuedFilesChanged(); }));
        });
    }
    sendFile(file) {
        return new Promise((resolve, reject) => {
            var _a, _b;
            file.status = Dropzone.UPLOADING;
            this.dropzone.emit('processing', file);
            if (!this.selectedContainer) {
                throw new Error('Select a container');
            }
            axios.post(route('video-upload.generate-upload-url', { videoContainer: this.selectedContainer.id }), {
                name: file.name,
                size: file.size,
                replace_video_id: (_b = (_a = window.__INITIAL_STATE__.upload) === null || _a === void 0 ? void 0 : _a.replace_video) === null || _b === void 0 ? void 0 : _b.id,
            })
                .then((response) => __awaiter(this, void 0, void 0, function* () {
                yield this.handleResponseErrors(response, file);
                const uploadData = response.data.data;
                file.hihaho_upload_id = response.data.data.id;
                this.dropzone.emit('sending', file);
                if (uploadData.uploadMethod === 'tus') {
                    this.uploadViaTus(uploadData, file)
                        .then((completedFile) => resolve(completedFile))
                        .catch((error) => reject(error));
                }
                else {
                    this.uploadViaAxios(uploadData, file)
                        .then((completedFile) => resolve(completedFile))
                        .catch((error) => reject(error));
                }
            }));
        });
    }
    uploadViaTus(uploadData, file) {
        return new Promise((resolve, reject) => {
            const upload = new tus.Upload(file, {
                uploadUrl: uploadData.url,
                retryDelays: [500, 3000, 5000, 10000],
                chunkSize: 10000000,
                metadata: {
                    filename: file.name,
                    filetype: file.type,
                },
                onError: (error) => __awaiter(this, void 0, void 0, function* () {
                    try {
                        yield this.handleResponseErrors(error, file);
                    }
                    catch (e) {
                        //
                    }
                    reject(error);
                }),
                onProgress: (bytesUploaded, bytesTotal) => {
                    this.uploadProgress(bytesUploaded, bytesUploaded / bytesTotal * 100, file);
                },
                onSuccess: () => __awaiter(this, void 0, void 0, function* () {
                    yield this.fileUploaded(file);
                    this.dropzone._finished([file], '', null);
                    resolve(file);
                }),
            });
            upload.start();
        });
    }
    uploadViaAxios(uploadData, file) {
        return new Promise((resolve, reject) => {
            axios.put(uploadData.url, file, {
                headers: {
                    'Content-Type': uploadData.contentType,
                },
                onUploadProgress: (progressEvent) => {
                    this.uploadProgress(progressEvent.loaded, Number(progressEvent.progress) * 100, file);
                },
            })
                .then((response) => __awaiter(this, void 0, void 0, function* () {
                yield this.handleResponseErrors(response, file);
                yield this.fileUploaded(file);
                this.dropzone._finished([file], '', null);
                resolve(file);
            }))
                .catch((error) => __awaiter(this, void 0, void 0, function* () {
                if (isObject(error.response)) {
                    const response = error.response;
                    try {
                        yield this.handleResponseErrors(response, file);
                    }
                    catch (e) {
                        //
                    }
                }
                reject(error);
            }));
        });
    }
    handleResponseErrors(response, file) {
        return __awaiter(this, void 0, void 0, function* () {
            if (typeof response === 'undefined') {
                return;
            }
            const status = 'status' in response ? Number(response.status) : null;
            if (status !== null && status < 300) {
                return;
            }
            if (file.status === Dropzone.ERROR) {
                return;
            }
            if (file.hihaho_upload_id) {
                yield this.fileUploadFailed(file);
            }
            const errorMessage = yield window.getAjaxErrorMessageAsString(response, '');
            const xhr = 'originalRequest' in response ? response.originalRequest
                : 'request' in response ? response.request : undefined;
            file.status = Dropzone.ERROR;
            this.dropzone.emit('error', file, errorMessage, xhr);
            this.dropzone.emit('complete', file);
            if (status !== null && status === 429) {
                window.showErrorAlert(yield __('video_uploader.error.rate_limit'), {
                    title: errorMessage,
                });
            }
            throw new Error(errorMessage);
        });
    }
    fileUploaded(file) {
        const url = route('video-upload.upload.finished', {
            uploadVideo: Number(file.hihaho_upload_id),
        });
        return axios.post(url);
    }
    fileUploadFailed(file) {
        const url = route('video-upload.upload.failed', {
            uploadVideo: Number(file === null || file === void 0 ? void 0 : file.hihaho_upload_id),
        });
        window.console.log('file upload failed', file);
        file.hihaho_upload_id = undefined;
        return axios.post(url);
    }
    amountQueuedFilesChanged() {
        this.amountQueuedFiles = this.dropzone.getQueuedFiles().length;
        this.isValid();
    }
    isValid() {
        const valid = this.dropzone.getRejectedFiles().length < 1 && this.amountQueuedFiles > 0;
        this.$emit('validation-updated', valid);
        return valid;
    }
    uploadProgress(bytesUploaded, percentage, file) {
        this.dropzone.emit('uploadprogress', file, percentage, bytesUploaded);
    }
};
VideoUploader = __decorate([
    Component({
        components: { UploadBgPlatformIcon },
    })
], VideoUploader);
export default VideoUploader;
