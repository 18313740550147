var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "hihaho-video group block" }, [
    _c(
      "a",
      {
        attrs: {
          href: _vm.route("player", { video: _vm.video.uuid }),
          target: "_blank",
          title: _vm.video.playlist_title,
        },
      },
      [
        _c(
          "div",
          {
            staticClass:
              "preview flex items-center justify-center bg-brand-default h-32 text-white rounded-lg",
          },
          [
            _c(
              "div",
              {
                staticClass:
                  "preview-container block w-full h-full relative rounded-lg align-bottom",
              },
              [
                _c("div", {
                  staticClass:
                    "hover-background block absolute inset-0 bg-brand-300 opacity-25 group-hover:opacity-0 rounded-lg",
                }),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass:
                      "absolute inset-0 flex items-center justify-center rounded-lg",
                  },
                  [
                    _c("PlayButtonIcon", {
                      staticClass:
                        "playlist-play-icon group-hover:opacity-100 opacity-0 h-6",
                      attrs: {
                        color: "#ED1C24",
                        "background-color": "#FFFFFF",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass:
                      "absolute bottom-0 flex items-baseline justify-center h-6 text-center bg-brand-300 opacity-0 group-hover:opacity-75 rounded-b-lg max-w-full w-full",
                  },
                  [
                    _c("p", {
                      staticClass: "max-w-full px-1 truncate text-brand-600",
                      domProps: {
                        textContent: _vm._s(_vm.video.playlist_title),
                      },
                    }),
                  ]
                ),
                _vm._v(" "),
                _c("img", {
                  staticClass: "w-full h-full object-cover rounded-lg",
                  attrs: { src: _vm.video.playlist_image_url, alt: "" },
                }),
              ]
            ),
          ]
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }