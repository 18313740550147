import { vxm } from '../store/store';
import { isObject } from '../../global/helpers/base';
export default class VideoPricing {
    constructor() {
        var _a, _b, _c;
        this.initialState = vxm.initialState.values.billing;
        this.taxPercentage = 1.21;
        this.amountVideosOptions = ((_a = this.initialState) === null || _a === void 0 ? void 0 : _a.amount_videos) || [];
        this.currencies = ((_b = this.initialState) === null || _b === void 0 ? void 0 : _b.supported_currencies) || [];
        this.discount = ((_c = this.initialState) === null || _c === void 0 ? void 0 : _c.video_order_discount) || 0;
    }
    price(amountVideos, amountViews, currency) {
        const basePrice = this.basePrice(amountVideos, amountViews, currency);
        if (this.hasCurrencySpecificPrize(currency)) {
            return basePrice;
        }
        return basePrice * this.exchangeRate(currency);
    }
    discountedPrice(amountVideos, amountViews, currency) {
        return this.price(amountVideos, amountViews, currency) * (1 - this.discountPercentage());
    }
    hasDiscount() {
        return this.discountPercentage() > 0;
    }
    discountPercentage() {
        return this.discount;
    }
    hasExchangeRates() {
        var _a;
        return Object.keys(((_a = this.initialState) === null || _a === void 0 ? void 0 : _a.exchange_rates) || {}).length > 1;
    }
    basePrice(amountVideos, amountViews, currency) {
        var _a, _b;
        let prices = (_a = this.initialState) === null || _a === void 0 ? void 0 : _a.prices;
        if (this.hasCurrencySpecificPrize(currency)) {
            prices = (_b = this.initialState) === null || _b === void 0 ? void 0 : _b.currency_specific_prices[currency.code];
        }
        if (!isObject(prices)) {
            throw Error('Unexpected price input');
        }
        const amountVideosPrices = prices[amountVideos];
        if (!isObject(amountVideosPrices)) {
            throw Error('Unexpected price input');
        }
        return parseFloat(String(amountVideosPrices[amountViews]));
    }
    hasCurrencySpecificPrize(currency) {
        var _a;
        return Object.hasOwn(((_a = this.initialState) === null || _a === void 0 ? void 0 : _a.currency_specific_prices) || {}, currency.code);
    }
    exchangeRate(currency) {
        var _a;
        return ((_a = this.initialState) === null || _a === void 0 ? void 0 : _a.exchange_rates[currency.code]) || 1;
    }
}
