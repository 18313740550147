var _a;
import Vue from 'vue';
import router from './router';
import store from './store/store';
import HihahoHeader from './components/global/HihahoHeader.vue';
import BillingModals from './components/billing/BillingModals.vue';
import CreateInteractionButton from './components/enrich/CreateInteractionButton.vue';
import GlobalMixin from './mixins/global';
import Playlist from './components/playlist/Playlist.vue';
import UploadVideoList from './components/upload/UploadVideoList.vue';
import VideoUploadPanel from './components/upload/VideoUploadPanel.vue';
import vSelect from 'vue-select';
import 'vue-select/dist/vue-select.css';
import { route } from 'ziggy-js';
import VueTailwind from 'vue-tailwind';
Vue.component('v-select', vSelect);
require('../global/axios');
// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
window.Vapor = require('laravel-vapor');
Date.now = () => new Date().getTime();
Vue.use(VueTailwind);
Vue.mixin(GlobalMixin);
Vue.mixin({
    methods: {
        route: (name, params, absolute, config) => route(name, params, absolute, config),
    },
});
new Vue({
    el: (_a = window.vueEl) !== null && _a !== void 0 ? _a : '#app',
    comments: true,
    components: {
        HihahoHeader,
        BillingModals,
        CreateInteractionButton,
        UploadVideoList,
        VideoUploadPanel,
        Playlist,
    },
    router,
    store,
});
