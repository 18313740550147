<template>
    <div v-if="hasFiles">
        <button @click="toggleExpanded()"
                class="files-folded text-center border-brand-600
                       text-brand-600 activated:rounded-xl rounded-xl border-2
                       p-1 pl-3 font-bold text-xs focus:outline-none">
            <span v-text="translations['show-files-pretext'] ?? ''"></span>

            <span v-text="this.files.length"></span>

            <span v-text="translations['show-files-posttext'] ?? ''"></span>

            <i class="material-icons font-bold text-xs float-right mr-1 mt-1 ml-4">
                folder
            </i>
        </button>

        <div v-if="isExpanded"
             class="file-list mt-2">
            <div class="grid grid-cols-6 md:grid-cols-12 gap-2">
                <file v-for="file in files"
                      :file="file"
                      :key="file.id"/>
            </div>
        </div>
    </div>
</template>

<script>
    import file from './File';
    import axios from '../../../global/axios';
    import {route} from 'ziggy-js';
    import { __ } from '../../../global/language';

    export default {
        name: 'FileList',
        components: {
            file,
        },
        data() {
            return {
                files: [],
                isExpanded: false,
                translations: {},
            }
        },
        mounted() {
            this.fetchFiles(route('playlist.files', {playlist_id: this.playlistId}));

            __([
                'show-files-pretext',
                'show-files-posttext',
            ])
                .then(translations => {
                    this.translations = translations;
                });
        },
        methods: {
            fetchFiles(url) {
                axios.get(url)
                    .then((response) => {
                        this.files = response.data.files;
                    })
                    .catch(error => {
                        console.log(error);
                    })
            },
            toggleExpanded() {
                this.isExpanded = ! this.isExpanded;
            },
        },
        props: {
            playlistId: Number,
        },
        computed: {
            hasFiles() {
                return this.files && this.files.length;
            },
        },
    }
</script>
