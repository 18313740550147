var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      attrs: {
        id: "Layer_1",
        "data-name": "Layer 1",
        xmlns: "http://www.w3.org/2000/svg",
        viewBox: "0 0 144 206",
      },
    },
    [
      _c("path", {
        attrs: {
          d: "M98.43,0H-.48V206h145V54.43Zm4.09,28.46L120.4,49.59H102.52ZM14.78,190.74V15.26H87.26V64.85h42V190.74Z",
          fill: "#333",
        },
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          d: "M108.63,103.66a42.86,42.86,0,0,0-2.61-3.53,44,44,0,0,0-68,0,42.86,42.86,0,0,0-2.61,3.53,43.92,43.92,0,0,0,2.23,51.73,44.3,44.3,0,0,0,12.47,10.73,43.83,43.83,0,0,0,43.86,0,44.3,44.3,0,0,0,12.47-10.73,43.88,43.88,0,0,0,2.23-51.73Zm3,22.2H98a69.62,69.62,0,0,0-3-18.73,78.09,78.09,0,0,0,9.4-2A39.45,39.45,0,0,1,111.67,125.86ZM91,147.49a154.93,154.93,0,0,0-16.83-1.11V130.14H93.75A65.1,65.1,0,0,1,91,147.49ZM50.25,130.14H69.86v16.24A154.93,154.93,0,0,0,53,147.49,65.1,65.1,0,0,1,50.25,130.14Zm3-22.42a158.64,158.64,0,0,0,16.58,1v17.1H50.25A64.61,64.61,0,0,1,53.28,107.72ZM74.14,88.47c6.1,1.08,11.46,6.78,15,15.14-4.41.49-9.43.81-15,.88Zm-4.28,0v16c-5.61-.07-10.63-.39-15-.88C58.4,95.25,63.76,89.55,69.86,88.47Zm0,62.19v16.87c-6.28-1.12-11.79-7.13-15.36-15.91A143.57,143.57,0,0,1,69.86,150.66Zm4.28,16.87V150.66a143.57,143.57,0,0,1,15.36,1C85.93,160.4,80.42,166.41,74.14,167.53Zm0-41.67v-17.1a158.64,158.64,0,0,0,16.58-1,64.61,64.61,0,0,1,3,18.14Zm27.4-24.39c-2.28.57-4.94,1.11-8,1.56a39.24,39.24,0,0,0-7.4-12.13A39.83,39.83,0,0,1,101.54,101.47ZM57.82,90.9A39.24,39.24,0,0,0,50.42,103c-3-.45-5.68-1-8-1.56A39.83,39.83,0,0,1,57.82,90.9ZM39.56,105.11a78.09,78.09,0,0,0,9.4,2,69.62,69.62,0,0,0-3,18.73H32.33A39.45,39.45,0,0,1,39.56,105.11Zm-7.23,25H46a70.06,70.06,0,0,0,2.76,18,73.2,73.2,0,0,0-9.6,2.18A39.53,39.53,0,0,1,32.33,130.14ZM42,154a76.51,76.51,0,0,1,8.17-1.73,39.87,39.87,0,0,0,7.68,12.86A39.79,39.79,0,0,1,42,154ZM86.18,165.1a39.87,39.87,0,0,0,7.68-12.86A76.51,76.51,0,0,1,102,154,39.79,39.79,0,0,1,86.18,165.1Zm18.69-14.81a73.2,73.2,0,0,0-9.6-2.18,70.06,70.06,0,0,0,2.76-18h13.64A39.53,39.53,0,0,1,104.87,150.29Z",
          fill: "#333",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }