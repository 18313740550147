<template>
    <svg version="1.1" id="Laag_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px"
         y="0px"
         viewBox="0 0 194 194" style="enable-background:new 0 0 194 194;" xml:space="preserve">
        <g>
	<path class="st0" d="M173.8,194.2H19.6c-11,0-19.8-8.9-19.8-19.8V20.2c0-11,8.9-19.8,19.8-19.8h154.2c11,0,19.8,8.9,19.8,19.8
		v154.2C193.6,185.3,184.7,194.2,173.8,194.2z"/>
            <g>
		<g>
			<g>
				<path class="st1" d="M165.4,170.8H28c-5.3,0-9.6-4.3-9.6-9.6V91.7c0-5.3,4.3-9.6,9.6-9.6s9.6,4.3,9.6,9.6v59.8h118.1V91.7
					c0-5.3,4.3-9.6,9.6-9.6c5.3,0,9.6,4.3,9.6,9.6v69.5C175,166.5,170.7,170.8,165.4,170.8z M28,86.1c-3.1,0-5.6,2.5-5.6,5.6v69.5
					c0,3.1,2.5,5.6,5.6,5.6h137.4c3.1,0,5.6-2.5,5.6-5.6V91.7c0-3.1-2.5-5.6-5.6-5.6c-3.1,0-5.6,2.5-5.6,5.6v61.8c0,1.1-0.9,2-2,2
					H35.6c-1.1,0-2-0.9-2-2V91.7C33.6,88.6,31.1,86.1,28,86.1z"/>
			</g>
            <path class="st2" d="M96.7,139.3c-5.3,0-9.6-4.3-9.6-9.6V62.3L71.6,76.8c-1.8,1.7-4.1,2.6-6.6,2.6c-2.6,0-5.2-1.1-7-3
				c-3.6-3.9-3.5-10,0.4-13.6l31.7-29.9c0.1-0.1,0.1-0.1,0.2-0.2c0.2-0.1,0.3-0.2,0.4-0.3c0.1-0.1,0.2-0.2,0.4-0.3
				c0.1-0.1,0.3-0.2,0.4-0.3c0.1-0.1,0.3-0.2,0.4-0.2c0.2-0.1,0.3-0.2,0.4-0.2c0.1-0.1,0.3-0.1,0.4-0.2c0.1-0.1,0.3-0.1,0.4-0.2
				c0.1-0.1,0.3-0.1,0.4-0.1c0.2-0.1,0.3-0.1,0.5-0.2c0.1,0,0.3-0.1,0.4-0.1c0.2-0.1,0.3-0.1,0.5-0.1c0.2,0,0.3-0.1,0.5-0.1l0.1,0
				c0.1,0,0.2,0,0.3,0c0.3,0,0.7-0.1,1,0c0.3,0,0.6,0,0.9,0c0.1,0,0.2,0,0.3,0c0,0,0.1,0,0.1,0c0.2,0,0.3,0,0.5,0.1
				c0.1,0,0.2,0.1,0.4,0.1l-0.3,2l0.5-1.9c0.1,0,0.3,0.1,0.4,0.1c0.1,0,0.3,0.1,0.4,0.1c0,0,0.1,0,0.1,0c0.1,0,0.2,0.1,0.4,0.1
				c0.1,0.1,0.3,0.1,0.4,0.2c0.2,0.1,0.3,0.2,0.5,0.2c0.1,0.1,0.3,0.1,0.4,0.2c0.2,0.1,0.3,0.2,0.4,0.3c0.1,0.1,0.3,0.2,0.4,0.3
				c0.1,0.1,0.3,0.2,0.4,0.3c0.1,0.1,0.2,0.2,0.4,0.3c0.1,0.1,0.2,0.1,0.2,0.2L135,62.8c0,0,0,0,0,0c3.9,3.6,4,9.8,0.4,13.6
				c-1.8,1.9-4.2,2.9-6.7,3c-2.6,0.1-5-0.9-6.9-2.6l-15.4-14.6v67.4C106.3,135,102,139.3,96.7,139.3z M89,55.6
				c0.3,0,0.5,0.1,0.8,0.2c0.7,0.3,1.2,1,1.2,1.8v72c0,3.1,2.5,5.6,5.6,5.6c3.1,0,5.6-2.5,5.6-5.6v-72c0-0.8,0.5-1.5,1.2-1.8
				c0.7-0.3,1.6-0.2,2.2,0.4l18.8,17.7c1.1,1,2.5,1.6,4,1.5c1.5,0,2.9-0.7,3.9-1.8c1-1.1,1.6-2.5,1.5-4c0-1.5-0.7-2.9-1.8-3.9
				l-31.8-30c-0.1-0.1-0.1-0.1-0.2-0.2c-0.1-0.1-0.1-0.1-0.2-0.2c-0.1-0.1-0.2-0.1-0.2-0.2s-0.2-0.1-0.2-0.1
				c-0.1-0.1-0.2-0.1-0.2-0.1c-0.1,0-0.1-0.1-0.2-0.1c-0.1-0.1-0.2-0.1-0.3-0.1l-0.2-0.1l0.5-1.9l-0.7,1.9c-0.1,0-0.1,0-0.2-0.1
				c-0.1,0-0.1,0-0.2-0.1l-0.3-0.1c-0.1,0-0.2,0-0.2,0l-0.2,0c-0.3,0-0.5,0-0.7,0c-0.2,0-0.3,0-0.5,0l-0.2,0c-0.1,0-0.2,0-0.3,0
				l-0.2,0c0,0-0.1,0-0.1,0c-0.1,0-0.2,0-0.3,0.1c-0.1,0-0.1,0-0.2,0.1c-0.2,0.1-0.2,0.1-0.3,0.1c-0.1,0-0.1,0.1-0.2,0.1
				c-0.1,0-0.2,0.1-0.3,0.1C94,35,93.9,35,93.9,35c-0.1,0.1-0.2,0.1-0.3,0.2c-0.1,0-0.1,0.1-0.2,0.1c-0.1,0.1-0.2,0.1-0.2,0.2
				c-0.1,0.1-0.1,0.1-0.2,0.2l-0.1,0.1L61.1,65.7c-1.1,1-1.7,2.4-1.8,3.9c0,1.5,0.5,2.9,1.5,4c1.1,1.1,2.5,1.8,4.1,1.8
				c1.4,0,2.8-0.5,3.9-1.5l18.8-17.7C88,55.8,88.5,55.6,89,55.6z"/>
		</g>
	</g>
</g>
</svg>
</template>

<script>
    export default {
        name: 'UploadBgPlatformIcon'
    }
</script>

<style scoped>
    .st0 {
        fill: #043348;
    }

    .st1 {
        fill: #53C6D6;
    }

    .st2 {
        fill: #ED257A;
    }
</style>
