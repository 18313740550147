var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import PurchaseVideosModal from './../modals/billing/PurchaseVideosModal.vue';
import TrialEnded from './../modals/billing/TrialEnded.vue';
import AfterPaymentResponse from './../modals/billing/AfterPaymentResponse.vue';
import Component from 'vue-class-component';
import { vxm } from '../../store/store';
import GlobalMixin from '../../mixins/global';
let BillingModals = class BillingModals extends GlobalMixin {
    get trialEnded() {
        return vxm.billing.trialEnded;
    }
    get activeModal() {
        return vxm.billing.activeModal;
    }
    mounted() {
        this.$nextTick(() => {
            if (this.$route.query.choose_plan === 'true' || this.$route.query.choose_plan === '1') {
                vxm.billing.activeModal = 'billing.purchase-videos';
            }
            else if (this.$route.query.video_order) {
                vxm.billing.activeModal = 'billing.video-order-feedback';
            }
            else if (vxm.billing.shouldShowTrialEnded) {
                vxm.billing.activeModal = 'billing.trial-ended';
            }
        });
        vxm.billing.$watch('activeModal', (newVal) => {
            document.documentElement.style.overflow = newVal !== null
                ? 'hidden'
                : 'auto';
        });
    }
};
BillingModals = __decorate([
    Component({
        components: {
            PurchaseVideosModal,
            TrialEnded,
            AfterPaymentResponse,
        },
    })
], BillingModals);
export default BillingModals;
