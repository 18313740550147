var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Component from 'vue-class-component';
import { vxm } from '../../store/store';
import VideoUploader from './VideoUploader.vue';
import { Watch } from 'vue-property-decorator';
import GlobalMixin from '../../mixins/global';
import { __ } from '../../../global/language';
let VideoUploadPanel = class VideoUploadPanel extends GlobalMixin {
    constructor() {
        super(...arguments);
        this.containers = vxm.initialState.values.upload ? vxm.initialState.values.upload.containers : [];
        this.selectedContainer = this.preferredContainer;
        this.uploaderIsValid = false;
        this.isUploading = false;
        this.translations = {};
    }
    mounted() {
        __([
            'videoUploaderStartUpload',
            'directUploadTitle',
            'directUploadDescription',
            'videoUploaderBrowserNotSupport',
            'videoUploaderUseSupportedBrowserToUploadVideo',
            'directUploadFirstSelectAContainer',
            'directUploadMaxUploadSizeDescription',
            'help_steps.select_page.upload.search_tip_start_upload',
        ])
            .then(translations => {
            this.translations = translations;
        });
    }
    onPropertyChanged(value) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        this.$refs.uploader.setContainer(value);
    }
    get maxUploadSizeDescriptionForSelectedContainer() {
        if (!this.selectedContainer) {
            return this.translations.directuploadfirstselectacontainer;
        }
        return String(this.translations.directuploadmaxuploadsizedescription)
            .replace('{Container}', this.selectedContainer.name)
            .replace('{Size}', this.selectedContainer.upload_max_file_size_readable);
    }
    get isBrowserCompatible() {
        if (typeof vxm.initialState.values.upload === 'undefined') {
            return false;
        }
        return vxm.initialState.values.upload.is_browser_compatible;
    }
    get preferredContainer() {
        const preferredContainer = this.containers.find((container) => {
            var _a;
            return container.id === ((_a = vxm.initialState.values.user) === null || _a === void 0 ? void 0 : _a.preferred_container_id);
        });
        if (preferredContainer) {
            return preferredContainer;
        }
        return this.containers.first();
    }
    submit() {
        if (!this.selectedContainer) {
            __('VideoUploaderSelectAContainerDescription').then(translation => window.showInfoAlert(translation));
            return;
        }
        if (!this.uploaderIsValid) {
            __('VideoUploaderCannotUploadInvalidFiles').then(translation => window.showInfoAlert(translation));
            return;
        }
        this.isUploading = true;
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        this.$refs.uploader.processQueue().catch(reason => {
            console.log('uploading failed', reason);
        });
    }
};
__decorate([
    Watch('selectedContainer')
], VideoUploadPanel.prototype, "onPropertyChanged", null);
VideoUploadPanel = __decorate([
    Component({
        components: {
            VideoUploader,
        },
    })
], VideoUploadPanel);
export default VideoUploadPanel;
