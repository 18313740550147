var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from 'vue';
import Component from 'vue-class-component';
import { vxm } from '../../store/store';
const Props = Vue.extend({
    props: {
        storeName: {
            type: String,
            required: true,
        },
        title: {
            type: String,
            default: '',
        },
        target: {
            type: String,
            required: true,
        },
        canClose: {
            type: Boolean,
            default: true,
        },
        hasMask: {
            type: Boolean,
            default: true,
        },
    }
});
let BootstrapModal = class BootstrapModal extends Props {
    constructor() {
        super(...arguments);
        this.isOpen = vxm.billing.activeModal === this.storeName;
        /**
         * TODO: Refactor modal to not use bootstrap so we have a single source of truth
         * Right now when bootstrap opens the modal, all that changes for vue is the classList
         * We have to watch this using a MutationObserver which is not a preferred way of doing this
         */
        this.observer = new MutationObserver(this.mutationEvent);
    }
    get show() {
        return this.isOpen;
    }
    close() {
        vxm.billing.activeModal = null;
    }
    destroyed() {
        const backdrop = document.querySelector('.modal-backdrop');
        if (backdrop) {
            backdrop.remove();
        }
        if (this.observer) {
            this.observer.disconnect();
        }
    }
    mounted() {
        vxm.billing.$watch('activeModal', (newVal) => {
            this.isOpen = this.storeName === newVal;
            this.$emit('open-state-changed', { isOpen: this.isOpen });
        });
        this.observer.observe(this.$el, {
            attributes: true,
            attributeOldValue: false,
            attributeFilter: ['class'],
        });
    }
    mutationEvent(mutations) {
        mutations.forEach((mutation) => {
            const target = mutation.target;
            if (target instanceof HTMLElement) {
                const isOpen = target.classList.contains('in');
                // Nothing changed
                if (isOpen === this.isOpen) {
                    return;
                }
                vxm.billing.activeModal = isOpen ? this.storeName : null;
            }
        });
    }
};
BootstrapModal = __decorate([
    Component
], BootstrapModal);
export default BootstrapModal;
