var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "search flex flex-wrap items-stretch w-full relative rounded-xl bg-white",
    },
    [
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.keywords,
            expression: "keywords",
          },
        ],
        staticClass:
          "shrink grow appearance-none leading-normal w-px flex-1 h-8 pl-3 bg-transparent font-italic relative text-black outline-none",
        attrs: { type: "search", placeholder: _vm.translations.search },
        domProps: { value: _vm.keywords },
        on: {
          keyup: function ($event) {
            if (
              !$event.type.indexOf("key") &&
              _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
            )
              return null
            return _vm.submit.apply(null, arguments)
          },
          input: function ($event) {
            if ($event.target.composing) return
            _vm.keywords = $event.target.value
          },
        },
      }),
      _vm._v(" "),
      _c("div", { staticClass: "flex -mr-px" }, [
        _c(
          "button",
          {
            staticClass:
              "flex items-center leading-normal whitespace-nowrap text-white text-2xs bg-brand-default rounded-2xl p-2 border-white border-4",
            on: { click: _vm.submit },
          },
          [
            _c("i", { staticClass: "material-icons font-bold text-xs" }, [
              _vm._v("search"),
            ]),
          ]
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }