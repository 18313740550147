import { createModule } from 'vuex-class-component/js';
const InitialStateVuexModule = createModule({
    namespaced: 'initialState',
    strict: false,
});
export default class InitialStateStore extends InitialStateVuexModule {
    constructor() {
        super(...arguments);
        this.values = Object.assign({}, window.__INITIAL_STATE__);
    }
}
