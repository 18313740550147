var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("nav", { staticClass: "bg-deep-blue-800" }, [
    _c("div", { class: _vm.container }, [
      _c("div", { staticClass: "mx-auto px-4 md:px-6 lg:px-0" }, [
        _c("div", { staticClass: "flex items-center justify-between h-13" }, [
          _c("div", { staticClass: "flex items-center" }, [
            _c(
              "a",
              {
                staticClass:
                  "shrink-0 relative flex lg:block items-center hover:no-underline focus:no-underline focus:outline-none active:no-underline active:outline-none",
                attrs: { href: _vm.route("dashboard.home") },
              },
              [_vm._t("logo")],
              2
            ),
            _vm._v(" "),
            _c("div", { staticClass: "tw-hidden md:block md:ml-6" }, [
              _c(
                "div",
                { staticClass: "flex space-x-4" },
                _vm._l(_vm.mainLinks, function (link) {
                  return _c("a", {
                    key: link.title,
                    staticClass:
                      "px-3 py-2 rounded-md text-sm font-bold leading-5 text-white hover:no-underline hover:text-sky-blue-400 focus:no-underline focus:text-sky-blue-400 focus:outline-none active:no-underline active:text-sky-blue-400 active:outline-none",
                    class: { "text-sky-blue-400": link.active },
                    attrs: { href: link.href, target: link.target },
                    domProps: { textContent: _vm._s(link.title) },
                  })
                }),
                0
              ),
            ]),
          ]),
          _vm._v(" "),
          _vm.branch
            ? _c("div", { staticClass: "text-white" }, [
                _c("i", { class: _vm.branchIcon }),
                _vm._v(" "),
                _c("span", { domProps: { textContent: _vm._s(_vm.branch) } }),
              ])
            : _vm._e(),
          _vm._v(" "),
          _c("div", { staticClass: "tw-hidden md:ml-6 md:block h-full" }, [
            _c(
              "div",
              { staticClass: "flex items-center space-x-3 h-full" },
              [
                _c("div", { staticClass: "relative h-full" }, [
                  _c("div", { staticClass: "h-full flex items-center" }, [
                    _c(
                      "button",
                      {
                        staticClass:
                          "flex items-center space-x-2 px-3 py-2 rounded-md text-sm font-bold leading-5 text-white hover:outline-none hover:text-sky-blue-400 focus:outline-none focus:text-sky-blue-400 active:outline-none active:text-sky-blue-400",
                        attrs: { id: "user-menu" },
                        on: {
                          click: function ($event) {
                            _vm.languages_open = !_vm.languages_open
                          },
                        },
                      },
                      [
                        _c("span", [
                          _c("i", { staticClass: "far fa-globe mr-2" }),
                          _vm._v(" "),
                          _c("span", {
                            domProps: {
                              textContent: _vm._s(_vm.currentLanguageName),
                            },
                          }),
                        ]),
                        _vm._v(" "),
                        _c("span", { staticClass: "caret" }),
                      ]
                    ),
                  ]),
                  _vm._v(" "),
                  _vm.languages_open
                    ? _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "click-outside",
                              rawName: "v-click-outside",
                              value: _vm.onClickOutsideLanguagesDropdown,
                              expression: "onClickOutsideLanguagesDropdown",
                            },
                          ],
                          staticClass:
                            "language-selector-dropdown absolute right-0 w-48 shadow-lg z-50",
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "py-1 bg-white ring-1 ring-black ring-opacity-5",
                            },
                            _vm._l(_vm.systemLocales, function (locale) {
                              return _c("a", {
                                key: locale.value,
                                staticClass:
                                  "block px-4 py-2 text-sm leading-5 hover:no-underline focus:no-underline focus:outline-none active:no-underline active:outline-none",
                                class: {
                                  "selected bg-sky-blue-800 text-white focus:bg-sky-blue-950 hover:text-white focus:text-white active:text-white":
                                    _vm.currentLanguage === locale.country_code,
                                  "text-deep-blue-800 hover:text-deep-blue-800 hover:bg-deep-blue-100 focus:text-deep-blue-800 focus:bg-deep-blue-100 active:text-deep-blue-800":
                                    _vm.currentLanguage !== locale.country_code,
                                },
                                attrs: {
                                  href: _vm.route("locale.update", {
                                    locale: locale.value,
                                  }),
                                },
                                domProps: { textContent: _vm._s(locale.name) },
                              })
                            }),
                            0
                          ),
                        ]
                      )
                    : _vm._e(),
                ]),
                _vm._v(" "),
                _vm.user && _vm.user.signed_in
                  ? _c(
                      "div",
                      {
                        staticClass: "relative h-full",
                        attrs: { dusk: "profileDropdown" },
                      },
                      [
                        _c("div", { staticClass: "h-full flex items-center" }, [
                          _c(
                            "button",
                            {
                              staticClass:
                                "flex items-center space-x-2 px-3 py-2 rounded-md text-sm font-bold leading-5 text-white hover:outline-none hover:text-sky-blue-400 focus:outline-none focus:text-sky-blue-400 active:outline-none active:text-sky-blue-400",
                              attrs: { dusk: "profileButton" },
                              on: {
                                click: function ($event) {
                                  _vm.profile_open = !_vm.profile_open
                                },
                              },
                            },
                            [
                              _c("span", { staticClass: "glyphicons user" }),
                              _vm._v(" "),
                              _c("span", {
                                domProps: {
                                  textContent: _vm._s(_vm.user.name),
                                },
                              }),
                              _vm._v(" "),
                              _c("span", { staticClass: "caret" }),
                            ]
                          ),
                        ]),
                        _vm._v(" "),
                        _vm.profile_open
                          ? _c(
                              "div",
                              {
                                directives: [
                                  {
                                    name: "click-outside",
                                    rawName: "v-click-outside",
                                    value: _vm.onClickOutsideProfileDropdown,
                                    expression: "onClickOutsideProfileDropdown",
                                  },
                                ],
                                staticClass:
                                  "absolute right-0 w-48 shadow-lg z-50",
                                staticStyle: { "min-width": "16rem" },
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "bg-white ring-1 ring-black ring-opacity-5 divide-y divide-deep-blue-100",
                                  },
                                  _vm._l(
                                    _vm.userDropdownLinks,
                                    function (linkGroup, index) {
                                      return _c(
                                        "div",
                                        { key: index, staticClass: "py-2" },
                                        [
                                          _vm._l(
                                            linkGroup,
                                            function (link, linkIndex) {
                                              return [
                                                link.href && !link.type
                                                  ? _c(
                                                      "a",
                                                      {
                                                        key: linkIndex,
                                                        staticClass:
                                                          "block px-4 py-2 text-sm overflow-hidden hover:no-underline focus:no-underline focus:outline-none active:no-underline active:outline-none",
                                                        class: {
                                                          "transition duration-1000 ease-in-out bg-cta-500 hover:bg-cta-700 m-1 rounded-md text-cta-100 hover:text-cta-100 focus:text-cta-100 active:text-cta-100":
                                                            link.cta,
                                                          "text-deep-blue-800 hover:text-deep-blue-800 hover:bg-deep-blue-100 focus:text-deep-blue-800 active:text-deep-blue-800 focus:bg-deep-blue-100":
                                                            !link.cta,
                                                        },
                                                        attrs: {
                                                          href: link.href,
                                                          target: link.target
                                                            ? link.target
                                                            : "_self",
                                                          dusk: link.dusk,
                                                        },
                                                      },
                                                      [
                                                        _c("span", {
                                                          staticClass:
                                                            "glyphicons pr-2",
                                                          class: link.icon,
                                                        }),
                                                        _vm._v(" "),
                                                        _c("span", {
                                                          domProps: {
                                                            textContent: _vm._s(
                                                              link.text
                                                            ),
                                                          },
                                                        }),
                                                      ]
                                                    )
                                                  : link.dataTarget
                                                  ? _c(
                                                      "a",
                                                      {
                                                        key: linkIndex,
                                                        staticClass:
                                                          "block px-4 py-2 text-sm overflow-hidden hover:no-underline focus:no-underline focus:outline-none active:no-underline active:outline-none",
                                                        class: {
                                                          "transition duration-1000 ease-in-out bg-cta-500 hover:bg-cta-700 m-1 rounded-md text-cta-100 hover:text-cta-100 focus:text-cta-100 active:text-cta-100":
                                                            link.cta,
                                                          "text-deep-blue-800 hover:text-deep-blue-800 hover:bg-deep-blue-100 focus:bg-deep-blue-100 focus:text-deep-blue-800 active:text-deep-blue-800":
                                                            !link.cta,
                                                        },
                                                        attrs: {
                                                          href: "#",
                                                          "data-target":
                                                            link.dataTarget,
                                                          "data-toggle":
                                                            link.dataToggle,
                                                        },
                                                      },
                                                      [
                                                        _c("span", {
                                                          staticClass:
                                                            "glyphicons pr-2",
                                                          class: link.icon,
                                                        }),
                                                        _vm._v(" "),
                                                        _c("span", {
                                                          domProps: {
                                                            textContent: _vm._s(
                                                              link.text
                                                            ),
                                                          },
                                                        }),
                                                      ]
                                                    )
                                                  : link.type === "POST"
                                                  ? _c(
                                                      "div",
                                                      { key: linkIndex },
                                                      [
                                                        _c(
                                                          "a",
                                                          {
                                                            staticClass:
                                                              "block px-4 py-2 text-sm text-deep-blue-800 hover:text-deep-blue-800 hover:no-underline hover:bg-deep-blue-100 focus:text-deep-blue-800 focus:no-underline focus:bg-deep-blue-100 focus:outline-none active:text-deep-blue-800 active:no-underline active:bg-deep-blue-100 active:outline-none",
                                                            attrs: {
                                                              dusk: "logoutLink",
                                                              href: "#",
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                $event.preventDefault()
                                                                return _vm.logoutAndShutdownIntercom.apply(
                                                                  null,
                                                                  arguments
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _c("span", {
                                                              staticClass:
                                                                "glyphicons",
                                                              class: link.icon,
                                                            }),
                                                            _vm._v(" "),
                                                            _c("span", {
                                                              domProps: {
                                                                textContent:
                                                                  _vm._s(
                                                                    link.text
                                                                  ),
                                                              },
                                                            }),
                                                          ]
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "form",
                                                          {
                                                            ref: "logoutForm",
                                                            refInFor: true,
                                                            staticStyle: {
                                                              display: "none",
                                                            },
                                                            attrs: {
                                                              action: link.href,
                                                              method: "POST",
                                                            },
                                                          },
                                                          [
                                                            _c("input", {
                                                              attrs: {
                                                                type: "hidden",
                                                                name: "_token",
                                                              },
                                                              domProps: {
                                                                value:
                                                                  link.csrf_token,
                                                              },
                                                            }),
                                                          ]
                                                        ),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                              ]
                                            }
                                          ),
                                        ],
                                        2
                                      )
                                    }
                                  ),
                                  0
                                ),
                              ]
                            )
                          : _vm._e(),
                      ]
                    )
                  : _c(
                      "a",
                      {
                        staticClass:
                          "px-3 py-2 rounded-md text-sm font-bold leading-5 text-white hover:text-sky-blue-400 hover:no-underline focus:text-sky-blue-400 focus:no-underline focus:outline-none active:text-sky-blue-400 active:no-underline active:outline-none",
                        attrs: { href: _vm.route("login") },
                      },
                      [
                        _c("span", { staticClass: "glyphicons log_in pr-1" }),
                        _vm._v(" "),
                        _c("span", {
                          domProps: {
                            textContent: _vm._s(_vm.translations.login),
                          },
                        }),
                      ]
                    ),
                _vm._v(" "),
                _vm._t("sso"),
              ],
              2
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "-mr-2 flex md:hidden" }, [
            _c(
              "button",
              {
                staticClass:
                  "inline-flex items-center justify-center p-2 rounded-md text-gray-300 hover:text-white hover:bg-gray-600 focus:text-white focus:bg-gray-600 focus:outline-none active:text-white active:bg-gray-600 active:outline-none",
                on: {
                  click: function ($event) {
                    _vm.mobile_open = !_vm.mobile_open
                  },
                },
              },
              [
                _c(
                  "svg",
                  {
                    staticClass: "h-6 w-6",
                    class: {
                      "tw-hidden": _vm.mobile_open,
                      block: !_vm.mobile_open,
                    },
                    attrs: {
                      fill: "none",
                      viewBox: "0 0 24 24",
                      stroke: "currentColor",
                    },
                  },
                  [
                    _c("path", {
                      attrs: {
                        "stroke-linecap": "round",
                        "stroke-linejoin": "round",
                        "stroke-width": "2",
                        d: "M4 6h16M4 12h16M4 18h16",
                      },
                    }),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "svg",
                  {
                    staticClass: "h-6 w-6",
                    class: {
                      block: _vm.mobile_open,
                      "tw-hidden": !_vm.mobile_open,
                    },
                    attrs: {
                      fill: "none",
                      viewBox: "0 0 24 24",
                      stroke: "currentColor",
                    },
                  },
                  [
                    _c("path", {
                      attrs: {
                        "stroke-linecap": "round",
                        "stroke-linejoin": "round",
                        "stroke-width": "2",
                        d: "M6 18L18 6M6 6l12 12",
                      },
                    }),
                  ]
                ),
              ]
            ),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "md:hidden",
          class: { block: _vm.mobile_open, "tw-hidden": !_vm.mobile_open },
        },
        [
          _c("div", { staticClass: "px-2 pt-2 pb-3" }, [
            _c(
              "div",
              { staticClass: "space-y-1 py-2" },
              _vm._l(_vm.links, function (link) {
                return _c("a", {
                  key: link.title,
                  staticClass:
                    "block px-3 py-2 rounded-md text-base font-medium text-gray-200 overflow-hidden hover:text-white hover:bg-gray-600 hover:no-underline focus:text-white focus:bg-gray-600 focus:no-underline focus:outline-none active:text-white active:bg-gray-600 active:no-underline active:outline-none",
                  class: {
                    "text-white bg-gray-900 hover:text-white focus:text-white active:text-white":
                      link.active,
                  },
                  attrs: {
                    href: link.href,
                    target: link.target ? link.target : "_self",
                  },
                  domProps: { textContent: _vm._s(link.title) },
                })
              }),
              0
            ),
            _vm._v(" "),
            _c("div", { staticClass: "space-y-1 py-2" }, [
              _c(
                "button",
                {
                  staticClass:
                    "block w-full text-left px-3 py-2 rounded-md text-base font-medium text-gray-200 overflow-hidden hover:text-white hover:bg-gray-600 focus:text-white focus:bg-gray-600 focus:outline-none active:text-white active:bg-gray-600 active:outline-none",
                  on: {
                    click: function ($event) {
                      _vm.mobile_languages_open = !_vm.mobile_languages_open
                    },
                  },
                },
                [
                  _c("span", [
                    _c("i", { staticClass: "far fa-globe mr-2" }),
                    _vm._v(" "),
                    _c("span", {
                      domProps: {
                        textContent: _vm._s(_vm.currentLanguageName),
                      },
                    }),
                  ]),
                  _vm._v(" "),
                  _c("span", { staticClass: "caret" }),
                ]
              ),
              _vm._v(" "),
              _vm.mobile_languages_open
                ? _c(
                    "div",
                    { staticClass: "px-4" },
                    _vm._l(_vm.systemLocales, function (locale) {
                      return _c("a", {
                        key: locale.value,
                        staticClass:
                          "block px-4 py-2 text-sm leading-5 text-white hover:text-white hover:no-underline focus:text-white focus:no-underline focus:outline-none active:text-white active:no-underline active:outline-none",
                        class: {
                          "bg-sky-blue-800":
                            _vm.currentLanguage === locale.country_code,
                          "hover:bg-deep-blue-600":
                            _vm.currentLanguage !== locale.country_code,
                        },
                        attrs: {
                          href: _vm.route("locale.update", {
                            locale: locale.value,
                          }),
                        },
                        domProps: { textContent: _vm._s(locale.name) },
                      })
                    }),
                    0
                  )
                : _vm._e(),
            ]),
            _vm._v(" "),
            _vm.user && _vm.user.signed_in
              ? _c("div", { staticClass: "space-y-1 py-2" }, [
                  _c(
                    "button",
                    {
                      staticClass:
                        "block w-full text-left px-3 py-2 rounded-md text-base font-medium text-gray-200 hover:text-white hover:bg-gray-600 focus:text-white focus:bg-gray-600 focus:outline-none active:text-white active:bg-gray-600 active:outline-none",
                      on: {
                        click: function ($event) {
                          _vm.mobile_profile_open = !_vm.mobile_profile_open
                        },
                      },
                    },
                    [
                      _c("span", { staticClass: "glyphicons user mr-2" }),
                      _vm._v(" "),
                      _c("span", {
                        domProps: { textContent: _vm._s(_vm.user.name) },
                      }),
                      _vm._v(" "),
                      _c("span", { staticClass: "caret" }),
                    ]
                  ),
                  _vm._v(" "),
                  _vm.mobile_profile_open
                    ? _c(
                        "div",
                        { staticClass: "px-4 divide-y divide-deep-blue-100" },
                        _vm._l(
                          _vm.userDropdownLinks,
                          function (linkGroup, index) {
                            return _c(
                              "div",
                              { key: index, staticClass: "py-2" },
                              [
                                _vm._l(linkGroup, function (link, linkIndex) {
                                  return [
                                    link.href && !link.type
                                      ? _c(
                                          "a",
                                          {
                                            key: linkIndex,
                                            staticClass:
                                              "block px-4 py-2 text-sm text-white overflow-hidden hover:text-white hover:no-underline hover:bg-deep-blue-600 focus:text-white focus:no-underline focus:bg-deep-blue-600 focus:outline-none active:text-white active:no-underline active:bg-deep-blue-600 active:outline-none",
                                            attrs: {
                                              href: link.href,
                                              target: link.target
                                                ? link.target
                                                : "_self",
                                            },
                                          },
                                          [
                                            _c("span", {
                                              staticClass: "glyphicons pr-2",
                                              class: link.icon,
                                            }),
                                            _vm._v(" "),
                                            _c("span", {
                                              domProps: {
                                                textContent: _vm._s(link.text),
                                              },
                                            }),
                                          ]
                                        )
                                      : link.dataTarget
                                      ? _c(
                                          "a",
                                          {
                                            key: linkIndex,
                                            staticClass:
                                              "block px-4 py-2 text-sm text-white hover:text-white hover:no-underline hover:bg-deep-blue-600 focus:text-white focus:no-underline focus:bg-deep-blue-600 focus:outline-none active:text-white active:no-underline active:bg-deep-blue-600 active:outline-none",
                                            attrs: {
                                              href: "#",
                                              "data-target": link.dataTarget,
                                              "data-toggle": link.dataToggle,
                                            },
                                          },
                                          [
                                            _c("span", {
                                              staticClass: "glyphicons pr-2",
                                              class: link.icon,
                                            }),
                                            _vm._v(" "),
                                            _c("span", {
                                              domProps: {
                                                textContent: _vm._s(link.text),
                                              },
                                            }),
                                          ]
                                        )
                                      : link.type === "POST"
                                      ? _c("div", { key: linkIndex }, [
                                          _c(
                                            "a",
                                            {
                                              staticClass:
                                                "block px-4 py-2 text-sm text-white hover:text-white hover:no-underline hover:bg-deep-blue-600 focus:text-white focus:no-underline focus:bg-deep-blue-600 focus:outline-none active:text-white active:no-underline active:bg-deep-blue-600 active:outline-none",
                                              attrs: { href: "#" },
                                              on: {
                                                click: function ($event) {
                                                  $event.preventDefault()
                                                  return _vm.logoutAndShutdownIntercom.apply(
                                                    null,
                                                    arguments
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c("span", {
                                                staticClass: "glyphicons",
                                                class: link.icon,
                                              }),
                                              _vm._v(" "),
                                              _c("span", {
                                                domProps: {
                                                  textContent: _vm._s(
                                                    link.text
                                                  ),
                                                },
                                              }),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "form",
                                            {
                                              ref: "logoutForm",
                                              refInFor: true,
                                              staticStyle: { display: "none" },
                                              attrs: {
                                                action: link.href,
                                                method: "POST",
                                              },
                                            },
                                            [
                                              _c("input", {
                                                attrs: {
                                                  type: "hidden",
                                                  name: "_token",
                                                },
                                                domProps: {
                                                  value: link.csrf_token,
                                                },
                                              }),
                                            ]
                                          ),
                                        ])
                                      : _vm._e(),
                                  ]
                                }),
                              ],
                              2
                            )
                          }
                        ),
                        0
                      )
                    : _vm._e(),
                ])
              : _c("div", { staticClass: "space-y-1 py-2" }, [
                  _c(
                    "a",
                    {
                      staticClass:
                        "block px-3 py-2 rounded-md text-base font-medium text-gray-200 hover:text-white hover:bg-gray-600 hover:no-underline focus:text-white focus:bg-gray-600 focus:no-underline focus:outline-none active:text-white active:bg-gray-600 active:no-underline active:outline-none",
                      attrs: { href: _vm.route("login") },
                    },
                    [
                      _c("span", { staticClass: "glyphicons log_in pr-1" }),
                      _vm._v(" "),
                      _c("span", {
                        domProps: {
                          textContent: _vm._s(_vm.translations.login),
                        },
                      }),
                    ]
                  ),
                ]),
          ]),
        ]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }