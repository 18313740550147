var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import InteractionLabel from './InteractionLabel.vue';
import Vue from 'vue';
import Component from 'vue-class-component';
import { __ } from '../../../global/language';
const Props = Vue.extend({
    props: {
        tag: {
            type: Boolean,
            default: false,
        },
        isInteraction: Boolean,
        isQuestion: Boolean,
        isQuestionOutsideModal: Boolean,
        isMenu: Boolean,
        interactionType: String,
        questionType: String,
        isBeta: Boolean,
        isNew: Boolean,
    }
});
let CreateInteractionButton = class CreateInteractionButton extends Props {
    constructor() {
        super(...arguments);
        this.name = '';
        this.tooltip = '';
    }
    created() {
        return __awaiter(this, void 0, void 0, function* () {
            const nameTranslation = `enrich.create_interaction_button.label.${this.type}`;
            const tooltipTranslation = `enrich.create_interaction_button.tooltip.${this.type}`;
            const translations = yield __([
                nameTranslation,
                tooltipTranslation,
            ]);
            this.name = translations[nameTranslation];
            this.tooltip = translations[tooltipTranslation];
        });
    }
    get duskLabel() {
        return `${this.type}InteractionButton`;
    }
    get type() {
        if (this.isQuestionOutsideModal) {
            return this.questionType;
        }
        if (this.isQuestion) {
            return 'question';
        }
        if (this.isMenu) {
            return 'menu';
        }
        return this.interactionType;
    }
    createInteraction() {
        var _a, _b, _c, _d, _e, _f;
        if (this.isQuestionOutsideModal) {
            this.createQuestionOutsideModal();
            return;
        }
        if (this.isQuestion) {
            (_b = (_a = window.editor) === null || _a === void 0 ? void 0 : _a.questionEditor) === null || _b === void 0 ? void 0 : _b.showQuestionTypeModal();
            return;
        }
        if (this.isMenu) {
            (_e = (_d = (_c = window.editor) === null || _c === void 0 ? void 0 : _c.interactionEditor) === null || _d === void 0 ? void 0 : _d.menuEditor) === null || _e === void 0 ? void 0 : _e.showMenuTypeModal();
            return;
        }
        (_f = window.editor) === null || _f === void 0 ? void 0 : _f.interactionEditor.newInteraction(this.interactionType);
    }
    createQuestionOutsideModal() {
        var _a;
        const questionTypeRadioElement = document.querySelector(`#selectQuestionTypeModal input[name="questionType"][value="${this.questionType}"]`);
        if (!questionTypeRadioElement) {
            return;
        }
        if ((_a = window.editor) === null || _a === void 0 ? void 0 : _a.questionEditor) {
            window.editor.questionEditor.newQuestionFlag = true;
        }
        questionTypeRadioElement.checked = true;
        const evt = document.createEvent('HTMLEvents');
        evt.initEvent('change', false, true);
        questionTypeRadioElement.dispatchEvent(evt);
    }
};
CreateInteractionButton = __decorate([
    Component({
        components: {
            InteractionLabel,
        }
    })
], CreateInteractionButton);
export default CreateInteractionButton;
