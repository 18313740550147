var render, staticRenderFns
import script from "./FileExtensionIcon.vue?vue&type=script&lang=ts"
export * from "./FileExtensionIcon.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/forge/adaptief-toetsen.hihaho.dev/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('2ebb57b8')) {
      api.createRecord('2ebb57b8', component.options)
    } else {
      api.reload('2ebb57b8', component.options)
    }
    
  }
}
component.options.__file = "resources/assets/js/vue/components/svg/playlist/FileExtensionIcon.vue"
export default component.exports