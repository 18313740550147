<template>
    <div class="hihaho-video group block">
        <a :href="route('player', {video: video.uuid})"
           target="_blank"
           :title="video.playlist_title">
            <div class="preview flex items-center justify-center bg-brand-default h-32 text-white rounded-lg">
                <div class="preview-container block w-full h-full relative rounded-lg align-bottom">
                    <div class="hover-background block absolute inset-0
                                bg-brand-300 opacity-25 group-hover:opacity-0 rounded-lg"></div>

                    <div class="absolute inset-0 flex items-center justify-center rounded-lg">
                        <PlayButtonIcon color="#ED1C24"
                                        background-color="#FFFFFF"
                                        class="playlist-play-icon group-hover:opacity-100 opacity-0 h-6"/>
                    </div>

                    <div class="absolute bottom-0 flex items-baseline justify-center h-6
                                text-center bg-brand-300 opacity-0 group-hover:opacity-75
                                rounded-b-lg max-w-full w-full">
                        <p class="max-w-full px-1 truncate text-brand-600"
                           v-text="video.playlist_title"></p>
                    </div>

                    <img :src="video.playlist_image_url"
                         alt=""
                         class="w-full h-full object-cover rounded-lg"/>
                </div>
            </div>
        </a>
    </div>
</template>

<script>
    import PlayButtonIcon from '../svg/playlist/PlayButtonIcon.vue';
    import VideoMixin from '@vue-mixins/video';

    export default {
        name: 'VideoItem',
        mixins: [VideoMixin],
        components: {
            PlayButtonIcon,
        },
        props: [
            'video',
            'color',
            'backgroundColor',
        ],
    }
</script>
