<template>
    <nav class="bg-deep-blue-800">
        <div :class="container">
            <div class="mx-auto px-4 md:px-6 lg:px-0">
                <div class="flex items-center justify-between h-13">
                    <div class="flex items-center">
                        <a :href="route('dashboard.home')"
                           class="shrink-0 relative flex lg:block items-center
                                  hover:no-underline
                                  focus:no-underline focus:outline-none
                                  active:no-underline active:outline-none">
                            <slot name="logo"></slot>
                        </a>

                        <div class="tw-hidden md:block md:ml-6">
                            <div class="flex space-x-4">
                                <a v-for="link in mainLinks"
                                   :key="link.title"
                                   :href="link.href"
                                   :target="link.target"
                                   class="px-3 py-2 rounded-md text-sm font-bold leading-5 text-white
                                          hover:no-underline hover:text-sky-blue-400
                                          focus:no-underline focus:text-sky-blue-400 focus:outline-none
                                          active:no-underline active:text-sky-blue-400 active:outline-none"
                                   :class="{'text-sky-blue-400': link.active}"
                                   v-text="link.title"></a>
                            </div>
                        </div>
                    </div>

                    <div v-if="branch"
                         class="text-white">
                        <i :class="branchIcon"></i>

                        <span v-text="branch"></span>
                    </div>

                    <div class="tw-hidden md:ml-6 md:block h-full">
                        <div class="flex items-center space-x-3 h-full">
                            <!-- Language selector dropdown -->
                            <div class="relative h-full">
                                <div class="h-full flex items-center">
                                    <button @click="languages_open = ! languages_open"
                                            class="flex items-center space-x-2 px-3 py-2 rounded-md text-sm font-bold leading-5 text-white
                                                   hover:outline-none hover:text-sky-blue-400
                                                   focus:outline-none focus:text-sky-blue-400
                                                   active:outline-none active:text-sky-blue-400"
                                            id="user-menu">
                                        <span>
                                            <i class="far fa-globe mr-2"></i>

                                            <span v-text="currentLanguageName"></span>
                                        </span>

                                        <span class="caret"></span>
                                    </button>
                                </div>

                                <div class="language-selector-dropdown absolute right-0 w-48 shadow-lg z-50"
                                     v-if="languages_open"
                                     v-click-outside="onClickOutsideLanguagesDropdown">
                                    <div class="py-1 bg-white ring-1 ring-black ring-opacity-5">
                                        <a v-for="locale in systemLocales"
                                           :key="locale.value"
                                           :href="route('locale.update', {locale: locale.value})"
                                           class="block px-4 py-2 text-sm leading-5
                                                  hover:no-underline
                                                  focus:no-underline focus:outline-none
                                                  active:no-underline active:outline-none"
                                           :class="{
                                               'selected bg-sky-blue-800 text-white focus:bg-sky-blue-950 hover:text-white focus:text-white active:text-white': currentLanguage === locale.country_code,
                                               'text-deep-blue-800 hover:text-deep-blue-800 hover:bg-deep-blue-100 focus:text-deep-blue-800 focus:bg-deep-blue-100 active:text-deep-blue-800': currentLanguage !== locale.country_code,
                                           }"
                                           v-text="locale.name"></a>
                                    </div>
                                </div>
                            </div>

                            <!-- Profile dropdown -->
                            <div class="relative h-full"
                                 dusk="profileDropdown"
                                 v-if="user && user.signed_in">
                                <div class="h-full flex items-center">
                                    <button @click="profile_open = ! profile_open"
                                            class="flex items-center space-x-2 px-3 py-2 rounded-md text-sm font-bold leading-5 text-white
                                                   hover:outline-none hover:text-sky-blue-400
                                                   focus:outline-none focus:text-sky-blue-400
                                                   active:outline-none active:text-sky-blue-400"
                                            dusk="profileButton">
                                        <span class="glyphicons user"></span>

                                        <span v-text="user.name"></span>

                                        <span class="caret"></span>
                                    </button>
                                </div>

                                <div class="absolute right-0 w-48 shadow-lg z-50"
                                     style="min-width: 16rem;"
                                     v-if="profile_open"
                                     v-click-outside="onClickOutsideProfileDropdown">
                                    <div class="bg-white ring-1 ring-black ring-opacity-5
                                                divide-y divide-deep-blue-100">
                                        <div v-for="(linkGroup, index) in userDropdownLinks"
                                             :key="index"
                                             class="py-2">
                                            <template v-for="(link, linkIndex) in linkGroup">
                                                <a v-if="link.href && ! link.type"
                                                   :key="linkIndex"
                                                   :href="link.href"
                                                   :target="link.target ? link.target : '_self'"
                                                   class="block px-4 py-2 text-sm overflow-hidden
                                                          hover:no-underline
                                                          focus:no-underline focus:outline-none
                                                          active:no-underline active:outline-none"
                                                   :class="{
                                                        'transition duration-1000 ease-in-out bg-cta-500 hover:bg-cta-700 m-1 rounded-md text-cta-100 hover:text-cta-100 focus:text-cta-100 active:text-cta-100': link.cta,
                                                        'text-deep-blue-800 hover:text-deep-blue-800 hover:bg-deep-blue-100 focus:text-deep-blue-800 active:text-deep-blue-800 focus:bg-deep-blue-100': ! link.cta,
                                                    }"
                                                   :dusk="link.dusk">
                                                    <span class="glyphicons pr-2"
                                                          :class="link.icon"></span>

                                                    <span v-text="link.text"></span>
                                                </a>

                                                <a v-else-if="link.dataTarget"
                                                   :key="linkIndex"
                                                   href="#"
                                                   :data-target="link.dataTarget"
                                                   :data-toggle="link.dataToggle"
                                                   class="block px-4 py-2 text-sm overflow-hidden
                                                          hover:no-underline
                                                          focus:no-underline focus:outline-none
                                                          active:no-underline active:outline-none"
                                                   :class="{
                                                        'transition duration-1000 ease-in-out bg-cta-500 hover:bg-cta-700 m-1 rounded-md text-cta-100 hover:text-cta-100 focus:text-cta-100 active:text-cta-100': link.cta,
                                                        'text-deep-blue-800 hover:text-deep-blue-800 hover:bg-deep-blue-100 focus:bg-deep-blue-100 focus:text-deep-blue-800 active:text-deep-blue-800': ! link.cta,
                                                    }">
                                                    <span class="glyphicons pr-2"
                                                          :class="link.icon"></span>

                                                    <span v-text="link.text"></span>
                                                </a>

                                                <div v-else-if="link.type === 'POST'"
                                                     :key="linkIndex">
                                                    <a dusk="logoutLink"
                                                       href="#"
                                                       class="block px-4 py-2 text-sm text-deep-blue-800
                                                              hover:text-deep-blue-800 hover:no-underline hover:bg-deep-blue-100
                                                              focus:text-deep-blue-800 focus:no-underline focus:bg-deep-blue-100 focus:outline-none
                                                              active:text-deep-blue-800 active:no-underline active:bg-deep-blue-100 active:outline-none"
                                                       v-on:click.prevent="logoutAndShutdownIntercom">
                                                        <span class="glyphicons"
                                                              :class="link.icon"></span>

                                                        <span v-text="link.text"></span>
                                                    </a>

                                                    <form :action="link.href"
                                                          method="POST"
                                                          style="display: none;"
                                                          ref="logoutForm">
                                                        <input type="hidden"
                                                               name="_token"
                                                               :value="link.csrf_token"/>
                                                    </form>
                                                </div>
                                            </template>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <a v-else
                               :href="route('login')"
                               class="px-3 py-2 rounded-md text-sm font-bold leading-5 text-white
                                      hover:text-sky-blue-400 hover:no-underline
                                      focus:text-sky-blue-400 focus:no-underline focus:outline-none
                                      active:text-sky-blue-400 active:no-underline active:outline-none">
                                <span class="glyphicons log_in pr-1"></span>

                                <span v-text="translations.login"></span>
                            </a>

                            <slot name="sso"></slot>
                        </div>
                    </div>

                    <div class="-mr-2 flex md:hidden">
                        <!-- Mobile menu button -->
                        <button @click="mobile_open = ! mobile_open"
                                class="inline-flex items-center justify-center p-2 rounded-md text-gray-300
                                       hover:text-white hover:bg-gray-600
                                       focus:text-white focus:bg-gray-600 focus:outline-none
                                       active:text-white active:bg-gray-600 active:outline-none">
                            <!-- Icon when menu is closed. -->
                            <!-- Menu open: "hidden", Menu closed: "block" -->
                            <svg class="h-6 w-6"
                                 :class="{ 'tw-hidden': mobile_open, 'block': ! mobile_open }"
                                 fill="none"
                                 viewBox="0 0 24 24"
                                 stroke="currentColor">
                                <path stroke-linecap="round"
                                      stroke-linejoin="round"
                                      stroke-width="2"
                                      d="M4 6h16M4 12h16M4 18h16"/>
                            </svg>
                            <!-- Icon when menu is open. -->
                            <!-- Menu open: "block", Menu closed: "hidden" -->
                            <svg class="h-6 w-6"
                                 :class="{ 'block': mobile_open, 'tw-hidden': ! mobile_open }"
                                 fill="none"
                                 viewBox="0 0 24 24"
                                 stroke="currentColor">
                                <path stroke-linecap="round"
                                      stroke-linejoin="round"
                                      stroke-width="2"
                                      d="M6 18L18 6M6 6l12 12"/>
                            </svg>
                        </button>
                    </div>
                </div>
            </div>

            <!-- Mobile menu -->
            <div class="md:hidden"
                 :class="{ 'block': mobile_open, 'tw-hidden': !mobile_open }">
                <div class="px-2 pt-2 pb-3">
                    <div class="space-y-1 py-2">
                        <a v-for="link in links"
                           :key="link.title"
                           :href="link.href"
                           :target="link.target ? link.target : '_self'"
                           class="block px-3 py-2 rounded-md text-base font-medium text-gray-200 overflow-hidden
                                  hover:text-white hover:bg-gray-600 hover:no-underline
                                  focus:text-white focus:bg-gray-600 focus:no-underline focus:outline-none
                                  active:text-white active:bg-gray-600 active:no-underline active:outline-none"
                           :class="{
                               'text-white bg-gray-900 hover:text-white focus:text-white active:text-white': link.active,
                           }"
                           v-text="link.title"></a>
                    </div>

                    <div class="space-y-1 py-2">
                        <button @click="mobile_languages_open = ! mobile_languages_open"
                                class="block w-full text-left px-3 py-2 rounded-md text-base font-medium text-gray-200 overflow-hidden
                                       hover:text-white hover:bg-gray-600
                                       focus:text-white focus:bg-gray-600 focus:outline-none
                                       active:text-white active:bg-gray-600 active:outline-none">
                            <span>
                                <i class="far fa-globe mr-2"></i>

                                <span v-text="currentLanguageName"></span>
                            </span>

                            <span class="caret"></span>
                        </button>

                        <div v-if="mobile_languages_open"
                             class="px-4">
                            <a v-for="locale in systemLocales"
                               :key="locale.value"
                               :href="route('locale.update', {locale: locale.value})"
                               class="block px-4 py-2 text-sm leading-5 text-white
                                      hover:text-white hover:no-underline
                                      focus:text-white focus:no-underline focus:outline-none
                                      active:text-white active:no-underline active:outline-none"
                               :class="{
                                   'bg-sky-blue-800': currentLanguage === locale.country_code,
                                   'hover:bg-deep-blue-600': currentLanguage !== locale.country_code,
                               }"
                               v-text="locale.name"></a>
                        </div>
                    </div>

                    <div class="space-y-1 py-2"
                         v-if="user && user.signed_in">
                        <button @click="mobile_profile_open = ! mobile_profile_open"
                                class="block w-full text-left px-3 py-2 rounded-md text-base font-medium text-gray-200
                                       hover:text-white hover:bg-gray-600
                                       focus:text-white focus:bg-gray-600 focus:outline-none
                                       active:text-white active:bg-gray-600 active:outline-none">
                            <span class="glyphicons user mr-2"></span>

                            <span v-text="user.name"></span>

                            <span class="caret"></span>
                        </button>

                        <div v-if="mobile_profile_open"
                             class="px-4 divide-y divide-deep-blue-100">
                            <div v-for="(linkGroup, index) in userDropdownLinks"
                                 :key="index"
                                 class="py-2">
                                <template v-for="(link, linkIndex) in linkGroup">
                                    <a v-if="link.href && !link.type"
                                       :key="linkIndex"
                                       :href="link.href"
                                       :target="link.target ? link.target : '_self'"
                                       class="block px-4 py-2 text-sm text-white overflow-hidden
                                              hover:text-white hover:no-underline hover:bg-deep-blue-600
                                              focus:text-white focus:no-underline focus:bg-deep-blue-600 focus:outline-none
                                              active:text-white active:no-underline active:bg-deep-blue-600 active:outline-none">
                                        <span class="glyphicons pr-2"
                                              :class="link.icon"></span>

                                        <span v-text="link.text"></span>
                                    </a>

                                    <a v-else-if="link.dataTarget"
                                       :key="linkIndex"
                                       href="#"
                                       :data-target="link.dataTarget"
                                       :data-toggle="link.dataToggle"
                                       class="block px-4 py-2 text-sm text-white
                                              hover:text-white hover:no-underline hover:bg-deep-blue-600
                                              focus:text-white focus:no-underline focus:bg-deep-blue-600 focus:outline-none
                                              active:text-white active:no-underline active:bg-deep-blue-600 active:outline-none">
                                        <span class="glyphicons pr-2"
                                              :class="link.icon"></span>

                                        <span v-text="link.text"></span>
                                    </a>

                                    <div v-else-if="link.type === 'POST'"
                                         :key="linkIndex">
                                        <a href="#"
                                           class="block px-4 py-2 text-sm text-white
                                                  hover:text-white hover:no-underline hover:bg-deep-blue-600
                                                  focus:text-white focus:no-underline focus:bg-deep-blue-600 focus:outline-none
                                                  active:text-white active:no-underline active:bg-deep-blue-600 active:outline-none"
                                           v-on:click.prevent="logoutAndShutdownIntercom">
                                            <span class="glyphicons"
                                                  :class="link.icon"></span>

                                            <span v-text="link.text"></span>
                                        </a>

                                        <form :action="link.href"
                                              method="POST"
                                              style="display: none;"
                                              ref="logoutForm">
                                            <input type="hidden"
                                                   name="_token"
                                                   :value="link.csrf_token"/>
                                        </form>
                                    </div>
                                </template>
                            </div>
                        </div>
                    </div>

                    <div class="space-y-1 py-2"
                         v-else>
                        <a :href="route('login')"
                           class="block px-3 py-2 rounded-md text-base font-medium text-gray-200
                                  hover:text-white hover:bg-gray-600 hover:no-underline
                                  focus:text-white focus:bg-gray-600 focus:no-underline focus:outline-none
                                  active:text-white active:bg-gray-600 active:no-underline active:outline-none">
                            <span class="glyphicons log_in pr-1"></span>

                            <span v-text="translations.login"></span>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </nav>
</template>

<script>
    import vClickOutside from 'v-click-outside'
    import { vxm } from '../../store/store';
    import { __ } from '../../../global/language';

    export default {
        directives: {
            clickOutside: vClickOutside.directive,
        },
        props: {
            links: Array,
            systemLocales: Array,
            userDropdownLinks: Array,
            container: String,
            branch: String | null,
            branchIcon: String | null,
        },
        data() {
            return {
                mobile_open: false,
                profile_open: false,
                mobile_profile_open: false,
                languages_open: false,
                mobile_languages_open: false,
                currentLanguage: vxm.initialState.values.translations.country_code,
                user: vxm.initialState.values.user,
                translations: {},
            }
        },
        mounted() {
            __([
                'login',
            ])
                .then(translations => {
                    this.translations = translations;
                });
        },
        computed: {
            mainLinks() {
                return this.links.filter(link => link.display)
            },

            currentLanguageName() {
                return this.systemLocales.find(locale => locale.country_code === this.currentLanguage)?.name ?? '';
            },
        },
        methods: {
            onClickOutsideProfileDropdown() {
                this.profile_open = false
            },
            onClickOutsideLanguagesDropdown() {
                this.languages_open = false
            },
            logoutAndShutdownIntercom() {
                if (typeof window.Intercom === 'function') {
                    window.Intercom('shutdown');
                }

                this.$refs.logoutForm[0].submit();
            },
        },
    }
</script>
