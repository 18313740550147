import Vue from 'vue';
import Vuex from 'vuex';
import { createProxy, extractVuexModule } from 'vuex-class-component/js';
import BillingStore from './BillingStore';
import InitialStateStore from './InitialStateStore';
Vue.use(Vuex);
const store = new Vuex.Store({
    modules: Object.assign(Object.assign({}, extractVuexModule(BillingStore)), extractVuexModule(InitialStateStore))
});
export default store;
export const vxm = {
    initialState: createProxy(store, InitialStateStore),
    billing: createProxy(store, BillingStore),
};
