<template>
    <svg version="1.1" id="Laag_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px"
         y="0px"
         viewBox="0 0 198 192" style="enable-background:new 0 0 198 192;" xml:space="preserve">
        <g>
	<g>
		<path class="st0" d="M173.4,92.1c-0.2-13.1-10.1-20.6-15.1-22.6c-5-2.6-103.6-53.7-105.7-54.8c0.1,0.1-1.6-0.8-3.3-1.4
			C37.6,9,23.9,14.7,18.7,25.9c-3.6,7.5-2.4,13.1-2.6,13.5c0,11.6,0,64.1,0,91.9c0.3,15.5-1.3,21,3,29.3l0,0
			c5.7,11.1,19.1,16.1,30.6,12.1c6.2-2.5,20.9-10.9,48.9-25.5c29-15.3,61.2-32.4,61.3-32.4c0.4-0.2,1.1-0.6,1.7-1
			C168.9,109.3,173.5,100.9,173.4,92.1z"/>
	</g>
</g>
        <g>
	<path class="st1" d="M123.4,132h-1.7c-0.1-5.2-1.7-20.1-15.4-32.1C103,97,99.5,93.7,96,90.5c5.8-5.7,10.1-10.2,11.1-11.2
		c2-1.8,12.7-12.2,14.4-28.5h1.8c3.7,0,6.8-3,6.8-6.8c0-3.7-3-6.8-6.8-6.8H47.8c-3.7,0-6.8,3-6.8,6.8c0,3.7,3,6.8,6.8,6.8h2.4
		c1.7,16.3,12.4,26.7,14.4,28.5c1,1,5.4,5.5,11.1,11.2c-3.5,3.3-7,6.6-10.3,9.5C51.7,112,50.1,126.8,50,132h-2.2
		c-3.7,0-6.8,3-6.8,6.8c0,3.7,3,6.8,6.8,6.8h75.7c3.7,0,6.8-3,6.8-6.8C130.2,135.1,127.2,132,123.4,132z M74.5,69.2L74.3,69
		l-0.3-0.2c-0.1-0.1-7.9-7-9.7-18.1h43c-1.8,11.1-9.6,18-9.7,18.1L97.4,69l-0.2,0.2c-0.1,0.1-4.9,5-11.3,11.4
		C79.4,74.2,74.6,69.3,74.5,69.2z M74.7,110.5c3.6-3.2,7.4-6.7,11.1-10.2c3.7,3.5,7.5,7,11.1,10.2c9.5,8.3,10.5,18.4,10.6,21.5H64.1
		C64.2,128.9,65.3,118.9,74.7,110.5z"/>
            <path class="st0" d="M123.4,37.2c3.7,0,6.8,3,6.8,6.8s-3,6.8-6.8,6.8h-1.8c-1.7,16.3-12.4,26.7-14.4,28.5c-1,1-5.4,5.5-11.1,11.2
		c3.5,3.3,7,6.5,10.3,9.4c13.7,12,15.3,26.9,15.4,32.1h1.7c3.7,0,6.8,3,6.8,6.8c0,3.7-3,6.8-6.8,6.8H47.8c-3.7,0-6.8-3-6.8-6.8
		c0-3.7,3-6.8,6.8-6.8H50c0.1-5.2,1.7-20.1,15.4-32.1c3.3-2.9,6.8-6.2,10.3-9.5c-5.8-5.7-10.2-10.2-11.1-11.2
		c-2-1.8-12.7-12.2-14.4-28.5h-2.4c-3.7,0-6.8-3-6.8-6.8s3-6.8,6.8-6.8H123.4 M85.9,80.6c6.5-6.4,11.2-11.3,11.3-11.4l0.2-0.2
		l0.3-0.2c0.1,0,7.9-7,9.7-18.1h-43c1.8,11.1,9.6,18,9.7,18.1l0.3,0.2l0.2,0.2C74.6,69.3,79.4,74.2,85.9,80.6 M64.1,132h43.5
		c-0.1-3.1-1.1-13.2-10.6-21.5c-3.6-3.2-7.4-6.7-11.1-10.2c-3.7,3.5-7.5,7.1-11.1,10.2C65.3,118.9,64.2,128.9,64.1,132 M123.4,33.2
		H47.8C41.8,33.2,37,38,37,44c0,5.6,4.2,10.2,9.7,10.7C49.5,70.2,59.5,80,61.8,82.1c0.8,0.9,3.9,4,8.2,8.3c-2.6,2.4-5,4.6-7.2,6.5
		c-12.3,10.8-15.6,23.7-16.5,31.2c-5.2,0.7-9.3,5.2-9.3,10.7c0,5.9,4.8,10.8,10.8,10.8h75.7c5.9,0,10.8-4.8,10.8-10.8
		c0-5.3-3.8-9.6-8.8-10.6c-0.8-7.6-4.2-20.5-16.5-31.3c-2.2-1.9-4.6-4.1-7.2-6.5c4.2-4.2,7.3-7.4,8.2-8.2c2.3-2.1,12.3-12,15.1-27.5
		c5.2-0.8,9.1-5.3,9.1-10.7C134.2,38,129.4,33.2,123.4,33.2L123.4,33.2z M69.5,54.7h32.6c-2.4,6.6-6.8,10.7-7.1,11L94.8,66l-0.1,0.1
		l-0.1,0.1l-0.2,0.2c0,0-3.4,3.6-8.5,8.5c-4.8-4.8-8.2-8.3-8.4-8.5l-0.2-0.2l-0.2-0.2L76.9,66l-0.2-0.2C76.5,65.6,72,61.4,69.5,54.7
		L69.5,54.7z M68.7,128c0.9-3.9,3.1-9.5,8.7-14.5c2.6-2.3,5.4-4.8,8.5-7.7c3.1,2.9,5.9,5.5,8.5,7.7c5.7,5,7.9,10.6,8.7,14.5H68.7
		L68.7,128z"/>
</g>
</svg>
</template>

<script>
    export default {
        name: 'PaymentPendingIcon'
    }
</script>

<style scoped>
    .st0 {
        fill: #53C6D6;
    }

    .st1 {
        fill: #FFFFFF;
    }
</style>
