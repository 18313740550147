var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "file items-center justify-center" }, [
    _c(
      "a",
      {
        staticClass: "w-full items-center",
        attrs: {
          href: _vm.file.url,
          target: "_blank",
          title: _vm.file.extension_type,
        },
      },
      [
        _c(
          "div",
          {
            staticClass:
              "description text-brand-500 text-center items-center justify-center content-center w-full text-xs",
          },
          [
            _c("file-extension-icon", {
              staticClass: "h-10 inline",
              attrs: { name: _vm.file.extension_type },
            }),
            _vm._v(" "),
            _c("p", {
              staticClass: "w-full truncate",
              domProps: { textContent: _vm._s(_vm.file.title) },
            }),
          ],
          1
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }