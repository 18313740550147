export default {
    methods: {
        duration: function (video) {
            let time = video.duration / 1000;
            const hours = Math.floor(time / 3600);
            if (hours > 0) {
                time = time - (hours * 3600);
            }
            const totalMinutes = Math.floor(time / 60);
            const totalSeconds = Math.floor(time - totalMinutes * 60);
            const minutes = (totalMinutes < 10) ? '0' + totalMinutes : totalMinutes;
            const seconds = (totalSeconds < 10) ? '0' + totalSeconds : totalSeconds;
            if (hours > 0) {
                return hours + ':' + minutes + ':' + seconds;
            }
            return minutes + ':' + seconds;
        }
    }
};
