import { render, staticRenderFns } from "./VideoOrderForm.vue?vue&type=template&id=4bbfddb9"
import script from "./VideoOrderForm.vue?vue&type=script&lang=ts"
export * from "./VideoOrderForm.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/forge/adaptief-toetsen.hihaho.dev/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('4bbfddb9')) {
      api.createRecord('4bbfddb9', component.options)
    } else {
      api.reload('4bbfddb9', component.options)
    }
    module.hot.accept("./VideoOrderForm.vue?vue&type=template&id=4bbfddb9", function () {
      api.rerender('4bbfddb9', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "resources/assets/js/vue/components/billing/VideoOrderForm.vue"
export default component.exports