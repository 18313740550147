var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      attrs: {
        id: "Layer_1",
        "data-name": "Layer 1",
        xmlns: "http://www.w3.org/2000/svg",
        viewBox: "0 0 144 206",
      },
    },
    [
      _c("path", {
        staticClass: "cls-1",
        attrs: {
          d: "M98.43,0H-.48V206h145V54.43Zm4.09,28.46L120.4,49.59H102.52ZM14.78,190.74V15.26H87.26V64.85h42V190.74Z",
        },
      }),
      _vm._v(" "),
      _c(
        "text",
        {
          staticClass: "cls-2",
          attrs: { transform: "translate(28.78 133.29)" },
        },
        [_vm._v("PDF")]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }