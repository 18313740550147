var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from 'vue';
import Component from 'vue-class-component';
import PaymentFailureIcon from './PaymentFailureIcon.vue';
import PaymentSuccessIcon from './PaymentSuccessIcon.vue';
import PaymentPendingIcon from './PaymentPendingIcon.vue';
const Props = Vue.extend({
    props: {
        success: Boolean,
        pending: Boolean,
    },
});
let PaymentIcon = class PaymentIcon extends Props {
    get failure() {
        return !this.success && !this.pending;
    }
    render(createElement) {
        if (this.pending) {
            return createElement(PaymentPendingIcon);
        }
        if (this.success) {
            return createElement(PaymentSuccessIcon);
        }
        return createElement(PaymentFailureIcon);
    }
};
PaymentIcon = __decorate([
    Component
], PaymentIcon);
export default PaymentIcon;
