<template v-if="playlist">
    <div class="playlist-item h-32 md:h-24">
        <a :href="playlist.route"
           :title="playlist.title">
            <div class="background w-full z-0 relative text-center align-middle h-32 md:h-24">
                <img v-if="playlist.thumbnail"
                     :src="playlist.thumbnail"
                     alt=""
                     class="object-cover top-0 left-0 z-0 rounded-lg h-32 md:h-24 w-full"/>

                <p class="truncate absolute inset-0
                          flex items-center justify-center
                          text-brand-600 text-xl font-bold z-5"
                   v-text="playlist.title"></p>

                <div v-if="playlist.id === activePlaylistId"
                     class="absolute top-0 left-0 bg-brand-100 z-4 opacity-50 w-full h-full rounded-lg">
                </div>

                <div v-else
                     class="absolute top-0 left-0 bg-brand-300 z-4 opacity-50 w-full h-full rounded-lg">
                </div>
            </div>
        </a>
    </div>
</template>

<script>
    export default {
        name: 'NavItem',
        props: ['playlist', 'activePlaylistId'],
    };
</script>
