<template>
    <div class="playlists pt-4 pb-6">
        <div class="playlist-row grid grid-cols-3 md:grid-cols-5 gap-3">
            <nav-item :active-playlist-id="activePlaylistId"
                      v-bind="{ playlist: playlist.parent || playlist }"/>

            <nav-item v-for="childPlaylist in playlist.children"
                      :key="childPlaylist.id"
                      :active-playlist-id="activePlaylistId"
                      v-bind="{ playlist: childPlaylist }"/>
        </div>
    </div>
</template>

<script>
    import NavItem from './NavItem';

    export default {
        name: 'PlaylistNav',
        components: {
            NavItem,
        },
        props: {
            playlist: {},
            activePlaylistId: Number,
        },
    }
</script>
