<template>
    <div class="video-list pt-8 pb-6"
         v-infinite-scroll="loadMore"
         infinite-scroll-disabled="busy"
         infinite-scroll-distance="10">
        <div class="grid grid-cols-3 md:grid-cols-5 gap-3">
            <video-item v-for="video in videos"
                        :video="video"
                        :key="video.id"/>
        </div>
    </div>
</template>

<script>
    import axios from '../../../global/axios';
    import {route} from 'ziggy-js';

    import VideoItem from './VideoItem';
    import infiniteScroll from 'vue-infinite-scroll';

    export default {
        name: 'VideoList',
        directives: {infiniteScroll},
        components: {
            VideoItem,
        },
        data() {
            return {
                data: [],
                videos: [],
                busy: true,
                nextPageUrl: null,
                videosUrl: null,
            }
        },
        mounted() {
            this.videosUrl = route('playlist.videos.get', {playlist_id: this.playlistId});
            this.fetchVideos(this.videosUrl);
        },
        methods: {
            fetchVideos(url, keywords) {
                axios.get(url, {params: {keywords}})
                    .then((response) => {
                        const result = response.data;
                        this.nextPageUrl = result.links.next;
                        this.videos = this.videos.concat(result.data);
                        this.busy = false;
                    })
                    .catch(function (error) {
                        console.log(error);
                    })
            },
            loadMore() {
                if (! this.nextPageUrl) {
                    return;
                }
                this.busy = true;
                this.fetchVideos(this.nextPageUrl);
            },
            clearResults() {
                this.nextPageUrl = null;
                this.videos = [];
            },
        },
        props: {
            playlistId: Number,
            keywords: String,
        },
        watch: {
            keywords() {
                this.clearResults();
                this.fetchVideos(this.videosUrl, this.keywords);
            },
        },
    }
</script>
