import { render, staticRenderFns } from "./VideoList.vue?vue&type=template&id=9b2cd2ac"
import script from "./VideoList.vue?vue&type=script&lang=js"
export * from "./VideoList.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/forge/adaptief-toetsen.hihaho.dev/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('9b2cd2ac')) {
      api.createRecord('9b2cd2ac', component.options)
    } else {
      api.reload('9b2cd2ac', component.options)
    }
    module.hot.accept("./VideoList.vue?vue&type=template&id=9b2cd2ac", function () {
      api.rerender('9b2cd2ac', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "resources/assets/js/vue/components/playlist/VideoList.vue"
export default component.exports