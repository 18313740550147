var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "playlist-item h-32 md:h-24" }, [
    _c(
      "a",
      { attrs: { href: _vm.playlist.route, title: _vm.playlist.title } },
      [
        _c(
          "div",
          {
            staticClass:
              "background w-full z-0 relative text-center align-middle h-32 md:h-24",
          },
          [
            _vm.playlist.thumbnail
              ? _c("img", {
                  staticClass:
                    "object-cover top-0 left-0 z-0 rounded-lg h-32 md:h-24 w-full",
                  attrs: { src: _vm.playlist.thumbnail, alt: "" },
                })
              : _vm._e(),
            _vm._v(" "),
            _c("p", {
              staticClass:
                "truncate absolute inset-0 flex items-center justify-center text-brand-600 text-xl font-bold z-5",
              domProps: { textContent: _vm._s(_vm.playlist.title) },
            }),
            _vm._v(" "),
            _vm.playlist.id === _vm.activePlaylistId
              ? _c("div", {
                  staticClass:
                    "absolute top-0 left-0 bg-brand-100 z-4 opacity-50 w-full h-full rounded-lg",
                })
              : _c("div", {
                  staticClass:
                    "absolute top-0 left-0 bg-brand-300 z-4 opacity-50 w-full h-full rounded-lg",
                }),
          ]
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }