<template>
    <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 144 206">
        <path class="cls-1"
              d="M98.43,0H-.48V206h145V54.43Zm4.09,28.46L120.4,49.59H102.52ZM14.78,190.74V15.26H87.26V64.85h42V190.74Z"/>
        <rect class="cls-1" x="32.04" y="87.74" width="80.11" height="15.26"/>
        <rect class="cls-1" x="32.04" y="118.26" width="80.11" height="15.26"/>
        <rect class="cls-1" x="32.04" y="148.78" width="80.11" height="15.26"/>
    </svg>
</template>

<script>
    export default {
        name: 'FileIcon'
    }
</script>

<style scoped>
    .cls-1 {
        fill: #333;
    }
</style>
