<template>
    <div>
        <page-header :active-playlist-id="$props.activePlaylistId"
                     @search="search"/>

        <div class="container mx-auto px-4">
            <file-list :playlist-id="$props.activePlaylistId"/>

            <video-list :playlist-id="$props.activePlaylistId"
                        :keywords="keywords"/>
        </div>
    </div>
</template>

<script>
    import Component from 'vue-class-component';
    import Vue from 'vue';
    import PlaylistNav from './PlaylistNav';
    import FileList from './FileList';
    import VideoList from './VideoList';
    import PageHeader from './PageHeader.vue';

    const Props = Vue.extend({
        props: {
            activePlaylistId: {
                required: true,
                type: Number,
            },
        }
    });

    @Component({
        components: {
            PlaylistNav,
            FileList,
            VideoList,
            PageHeader,
        },
        methods: {
            search(keywords) {
                this.keywords = keywords;
            }
        },
        data() {
            return {
                keywords: '',
            };
        }
    })
    export default class Playlist extends Props {
        //
    }
</script>
