var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { createModule, mutation } from 'vuex-class-component/js';
const BillingVuexModule = createModule({
    namespaced: 'billing',
    strict: false,
});
const trialEndedExcludedRoutes = [
    'container.claim-invite',
];
export default class BillingStore extends BillingVuexModule {
    constructor() {
        var _a;
        super(...arguments);
        this.activeModal = null;
        this.trialEnded = ((_a = window.__INITIAL_STATE__.user) === null || _a === void 0 ? void 0 : _a.is_account_locked) || false;
        this.shouldShowTrialEnded = this.trialEnded && !trialEndedExcludedRoutes.includes(window.__INITIAL_STATE__.request.route.name);
    }
    changeModal(newValue) {
        this.activeModal = newValue;
    }
}
__decorate([
    mutation
], BillingStore.prototype, "changeModal", null);
