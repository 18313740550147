var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import BootstrapModal from './../BootstrapModal.vue';
import Component from 'vue-class-component';
import { vxm } from '../../../store/store';
import GlobalMixin from '../../../mixins/global';
import { __ } from '../../../../global/language';
let TrialEnded = class TrialEnded extends GlobalMixin {
    constructor() {
        var _a;
        super(...arguments);
        this.modalName = 'billing.trial-ended';
        this.firstName = (_a = vxm.initialState.values.user) === null || _a === void 0 ? void 0 : _a.first_name;
        this.translations = {};
    }
    mounted() {
        vxm.billing.$watch('activeModal', (newVal) => {
            if (!newVal && vxm.billing.trialEnded) {
                vxm.billing.activeModal = 'billing.trial-ended';
            }
        });
        __([
            'trialEndedModalUnlock',
            'trialEndedModalGreeting',
            'trialEndedModalMessage',
            'trialEndedModalTeam',
        ])
            .then(translations => {
            this.translations = translations;
        });
    }
    setModal(modal) {
        vxm.billing.activeModal = modal;
    }
};
TrialEnded = __decorate([
    Component({
        components: {
            BootstrapModal,
        },
    })
], TrialEnded);
export default TrialEnded;
