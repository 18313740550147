var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Component from 'vue-class-component';
import Vue from 'vue';
import DocIcon from './DocIcon.vue';
import PdfIcon from './PdfIcon.vue';
import ImageIcon from './ImageIcon.vue';
import FileIcon from './FileIcon.vue';
import WebIcon from './WebIcon.vue';
const Props = Vue.extend({
    props: {
        name: String,
        file: String,
    },
    components: {
        DocIcon,
        PdfIcon,
        ImageIcon,
        FileIcon,
        WebIcon,
    }
});
let FileExtensionIcon = class FileExtensionIcon extends Props {
    render(createElement) {
        if (this.name === 'doc') {
            return createElement(DocIcon);
        }
        if (this.name === 'pdf') {
            return createElement(PdfIcon);
        }
        if (this.name === 'image') {
            return createElement(ImageIcon);
        }
        if (this.name === 'file') {
            return createElement(FileIcon);
        }
        if (this.name === 'web') {
            return createElement(WebIcon);
        }
    }
};
FileExtensionIcon = __decorate([
    Component
], FileExtensionIcon);
export default FileExtensionIcon;
