var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "infinite-scroll",
          rawName: "v-infinite-scroll",
          value: _vm.loadMore,
          expression: "loadMore",
        },
      ],
      staticClass: "video-list pt-8 pb-6",
      attrs: {
        "infinite-scroll-disabled": "busy",
        "infinite-scroll-distance": "10",
      },
    },
    [
      _c(
        "div",
        { staticClass: "grid grid-cols-3 md:grid-cols-5 gap-3" },
        _vm._l(_vm.videos, function (video) {
          return _c("video-item", { key: video.id, attrs: { video: video } })
        }),
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }