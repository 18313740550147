var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("header", { staticClass: "w-full relative" }, [
    _c("div", { staticClass: "background absolute w-full z-0" }, [
      _c("div", { staticClass: "absolute inset-0 z-5" }),
      _vm._v(" "),
      _c("img", {
        staticClass: "image w-full object-cover h-64",
        attrs: { src: _vm.activePlaylist.header_img_url, alt: "" },
      }),
    ]),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass:
          "container relative w-full mx-auto px-4 pt-4 z-10 justify-center",
      },
      [
        _c(
          "div",
          {
            staticClass:
              "flex w-1/2 mx-auto mb-4 h-16 md:h-32 space-x-4 overflow-hidden",
          },
          [
            _c("div", { staticClass: "logo flex-none" }, [
              _c("img", {
                staticClass: "h-16 w-auto flex",
                attrs: {
                  src: _vm.activePlaylist.header_logo_url,
                  alt: _vm.translations["playlist-logo-alt"] ?? "",
                  title: _vm.translations["playlist-logo-title"] ?? "",
                },
              }),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "flex-auto" }, [
              _c("h3", {
                staticClass: "title flex-auto text-brand-600 mt-0",
                domProps: { textContent: _vm._s(_vm.activePlaylist.title) },
              }),
              _vm._v(" "),
              _c("p", {
                staticClass:
                  "description flex-none text-brand-default leading-normal invisible md:visible",
                domProps: { innerHTML: _vm._s(_vm.activePlaylist.description) },
              }),
            ]),
          ]
        ),
        _vm._v(" "),
        _c("playlist-search", { on: { search: _vm.search } }),
        _vm._v(" "),
        _c("playlist-nav", {
          attrs: {
            playlist: _vm.activePlaylist,
            "active-playlist-id": _vm.$props.activePlaylistId,
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }